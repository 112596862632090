@import "_colors";
@import "_font";
@import "design-constants";

html {
  touch-action: manipulation;
}

/* ////////////////////////////////////////////////////////////////////////// */
// 全ページ共通。
/* ////////////////////////////////////////////////////////////////////////// */

body.front {
  .page {
    margin-bottom: 32px;

    &.gray-background {
      margin-bottom: 0;
    }

    &.container-fluid {
      @media screen and (max-width: 374px) {
        padding-left: 10px;
        padding-right: 10px;

        .row {
          margin-left: -10px;
          margin-right: -10px;
        }
      }
    }
  }
}

// デザイン幅のコンテナ
.design-width {
  max-width: $design-width;
  margin-left: auto;
  margin-right: auto;
}

// ページタイトル。
.modal-header.page-title-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-title-col,
.modal-header.page-title-header {
  margin-top: 24px;
  margin-bottom: 24px;

  .page-title-alphabet {
    text-align: center;
    font-size: $font-size-pc-page-title-alphabet;

    &, & > span {
      font-weight: normal;
    }

    @media (max-width: $header-collapse-max-width) {
      font-size: $font-size-sp-page-title-alphabet;
    }

    &.error {
      color: $tertiary-color;
    }
  }

  .page-title-separator {
    text-align: center;
    height: 24px;
  }

  // ページタイトル
  .page-title {
    font-size: $font-size-pc-page-title;
    text-align: center;
    color: $text-color-dark;
    font-weight: bold;
    @media (max-width: $header-collapse-max-width) {
      font-size: $font-size-sp-page-title;
    }

    & > span {
      font-weight: bold;
    }
  }
}

/* ////////////////////////////////////////////////////////////////////////// */
// ボタン
/* ////////////////////////////////////////////////////////////////////////// */
.btn {
  user-select: none;
}

.btn-main {
  width: 280px;
  border-radius: 32px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-primary,
.btn-primary:focus {
  background: $primary-color;
  border-color: $primary-color;
  color: white;
}

.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):active {
  background: $primary-color;
  border-color: $primary-color;
  color: white;
  opacity: 0.6;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: white;
  background-color: $disabled-color;
  border-color: $disabled-color;
}

.btn-outline-primary,
.btn-outline-primary:focus {
  color: $primary-color;
  background: white;
  border-color: $primary-color;
}

.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: $primary-color;
  border-color: $primary-color;
  background: white;
  opacity: 0.6;
}


.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: $disabled-color;
  background: white;
  border-color: $disabled-color;
}

.btn-primary-inv,
.btn-primary-inv:focus {
  background: white;
  border-color: white;
  color: $primary-color;
}


.btn-secondary,
.btn-secondary:focus {
  background: $secondary-color;
  border-color: $secondary-color;
  color: white;
}

.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled):active {
  background: $secondary-color;
  border-color: $secondary-color;
  color: white;
  opacity: 0.6;
}

.btn-outline-secondary,
.btn-outline-secondary:focus {
  background: white;
  border-color: $secondary-color;
  color: $secondary-color;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background: white;
  border-color: $secondary-color;
  color: $secondary-color;
  opacity: 0.6;
}


.btn-success,
.btn-success:focus {
  background: $tertiary-color;
  border-color: $tertiary-color;
  color: white;
}

.btn-success:hover,
.btn-success:not(:disabled):not(.disabled):active {
  background: $tertiary-color-hover;
  border-color: $tertiary-color-hover;
  color: white;
}

.btn-outline-success,
.btn-outline-success:focus {
  background: white;
  border-color: $tertiary-color;
  color: $tertiary-color;
}

.btn-outline-success:hover,
.btn-outline-success:not(:disabled):not(.disabled):active {
  background: white;
  border-color: $tertiary-color-hover;
  color: $tertiary-color-hover;
}

.btn-text-success,
.btn-text-success:focus {
  background: white;
  border: none;
  color: $tertiary-color;
}

.btn-text-success:hover,
.btn-text-success:not(:disabled):not(.disabled):active {
  background: white;
  border: none;
  color: $tertiary-color-hover;
}

.btn-outline-info,
.btn-outline-info:focus {
  background: white;
  border-color: $tertiary-color-hover;
  color: $tertiary-color-hover;
}

.btn-outline-info:hover,
.btn-outline-info:not(:disabled):not(.disabled):active {
  background: white;
  border-color: $tertiary-color-hover;
  color: $tertiary-color-hover;
  opacity: 0.6;
}

.btn-outline-gray,
.btn-outline-gray:focus {
  color: $text-black-alpha-3;
  background: white;
  border-color: $border-color-black-alpha-2;
}

.btn-outline-gray:hover,
.btn-outline-gray:not(:disabled):not(.disabled):active {
  color: $text-black-alpha-3;;
  border-color: $border-color-black-alpha-2;
  background: white;
  opacity: 0.6;
}


.btn-text-gray,
.btn-text-gray:focus {
  background: white;
  border: none;
  color: $text-black-alpha-2;
}

.btn-text-gray:hover,
.btn-text-gray:not(:disabled):not(.disabled):active {
  background: white;
  border: none;
  color: $text-black-alpha-2;
  opacity: 0.6;
}

.button-container {
  display: flex;
  justify-content: center;
}

.btn-big {
  width: 298px;
  border-radius: 28px;
  font-size: $font-size-pc-button-caption;
  padding-top: 15px;
  padding-bottom: 15px;
}

.btn-md {
  max-width: 165px;
  width: calc((100% - 13px) / 2);
  font-size: $font-size-btn-md;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;

  @media screen and (max-width: $width-xs) {
    font-size: 10px;
    width: 140px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  border-radius: 20px;
}

/* ////////////////////////////////////////////////////////////////////////// */
// フォーム関連
/* ////////////////////////////////////////////////////////////////////////// */

.form-container-col {
  display: flex;
  justify-content: center;

  form {
    max-width: 640px;
    width: 100%;
  }
}


.form-label {
  font-size: $font-size-pc-input-label;
  color: $text-color-dark;
  margin-bottom: 12px;
  font-weight: normal;
}

.form-label.required,
label.required {
  position: relative;

  &::after {
    content: "必須";
    display: inline;
    color: $tertiary-color;
    border: 1px solid $tertiary-color;
    border-radius: 4px;
    position: absolute;
    right: -45px;
    top: -5px;
    padding: 4px;
    font-size: $font-size-pc-input-label;
  }
}

.select-wrapper {
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    appearance: none;
    outline: none;
    background: $background-color;
    font-size: $font-size-input;
    color: black;
    border-radius: 4px;
    //border: 1px solid #ced4da;

    &.input-bg-color {
      background: $input-background-color;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    //noinspection CssUnknownTarget
    background: url("/img/icons/arrow-down.svg");
    position: absolute;
    right: 0;
    top: 7px;
    pointer-events: none;
  }
}

input.form-control,
textarea.form-control {
  background-color: $input-background-color;

  &:focus {
    box-shadow: none;
    border-color: $input-outline-color-focus;
    background-color: $input-background-color;
  }
}

input[type=checkbox] {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox-label {
  position: relative;
  user-select: none;
  font-weight: normal;
  cursor: pointer;
  box-sizing: border-box;
  padding: 2px 6px 6px;
  border: 1px solid transparent;
  border-radius: 10px;
  margin-left: -3px;

  :focus ~ & {
    border: 1px solid $input-outline-color-focus;
  }

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    background: $disabled-color;
    display: inline-block;
    position: relative;
    top: 6px;
    left: 0;
    margin-right: 8px;
    border-radius: 8px;
  }

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    //noinspection CssUnknownTarget
    background-image: url("/img/icons/check.svg");
    top: 7px;
    left: 6px;
  }

  &.adjust-font-size {
    &::before,
    &::after {
      @media screen and (max-width: 429px) {
        top: 7px;
      }
    }
  }

  input[type=checkbox]:checked ~ & {
    color: $primary-color;
    font-weight: bold;

    &::before {
      background: $input-outline-color-focus;
    }
  }
}

.checkbox-label-with-image {
  padding: 0;
  box-sizing: border-box;
  border: none;

  &::before {
    display: block;
  }

  &::after {
    left: 1px;
  }

  :focus ~ & {
    border: none;
  }

  :checked ~ & {
    img {
      border: solid 3px $primary-color !important;
    }
  }
  
  &--readonly {
    cursor: default;
  }

  &--content-txt::before {
    background: transparent !important;
  }

  &--content-txt::after {
    background: none;
    font-size: 10px;
    width: auto;
  }
 
  :checked ~ &--content-txt::after {
    content: 'データ写真';
  }
}

.toggle-checkbox-label {
  background: white;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
  padding: 7px 12px 7px 24px;
  cursor: pointer;
  user-select: none;

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    //noinspection CssUnknownTarget
    background-image: url("/img/icons/check-gray.svg");
    top: 7px;
    left: 0;
  }

  input[type=checkbox]:checked ~ & {
    background: $input-outline-color-focus;
    color: white;
  }

  input[type=checkbox]:checked ~ &::after {
    //noinspection CssUnknownTarget
    background-image: url("/img/icons/check.svg");
  }

  :focus ~ & {
    box-shadow: 0 0 16px $primary-color;
  }
}

.form-check-input[type=radio] {
  background: transparent !important;
  border: none !important;
  display: none;
}

input[type=radio] {
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

.radio-label {
  position: relative;
  user-select: none;
  font-weight: normal;
  color: $text-black-alpha-2;
  cursor: pointer;

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    //background: $disabled-color;
    display: inline-block;
    position: relative;
    top: 5px;
    left: 0;
    margin-right: 8px;
    //border-radius: 8px;
  }

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    //noinspection CssUnknownTarget
    background-image: url("/img/icons/circle-gray.svg");
    background-size: contain;
    top: 5px;
    left: 0;
  }


  input[type=radio]:checked ~ & {
    font-weight: bold;
    color: black;

    &::after {
      //noinspection CssUnknownTarget
      background-image: url("/img/icons/circle-checked-primary.svg");
    }
  }
}

.invalid-feedback {
  color: $tertiary-color;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: $tertiary-color;

  &:focus {
    box-shadow: none;
  }
}

// 郵便番号・住所自動入力
.input-group.postal-code {
  input {
    max-width: 120px;
    border-radius: 0.25rem !important;
    margin-right: 1px;
  }

  .input-group-append.auto-address-input {
    button {
      border: none;
    }
  }
}

// 電話番号入力
.input-group.phone-number input {
  max-width: 80px;
  margin-right: 12px;
  border-radius: 0.25rem !important;
}

/* ////////////////////////////////////////////////////////////////////////// */
// ユーザー名を大きく表示する。
/* ////////////////////////////////////////////////////////////////////////// */
.user-name {
  font-weight: bold;
  font-size: 1.1em;
}

/* ////////////////////////////////////////////////////////////////////////// */
// 見出し
/* ////////////////////////////////////////////////////////////////////////// */

.section-title {
  background: $input-background-color;
  font-size: $font-size-section-title;
  font-weight: bold;
  padding: 13px 26px 13px 28px;
  position: relative;
  margin-bottom: 24px;
  color: black;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: 18px;
    left: 12px;
    //noinspection CssUnknownTarget
    background: url("/img/icons/rhombus.svg") no-repeat center;
    background-size: contain;
  }
  
  .section-title-supplement {
    font-size: $font-size-section-title-supplement;
    font-weight: 300;
    display: block;
    margin-top: 8px;
  }
}

/* ////////////////////////////////////////////////////////////////////////// */
// リスト
/* ////////////////////////////////////////////////////////////////////////// */

.ordered-list-with-brackets {
  padding-left: 0;

  li {
    $space: 1.5 * $font-size-fixed-page-content;
    list-style-type: none;
    padding-left: $space;
    text-indent: -$space;
    counter-increment: cnt;

    &::before {
      content: "(" counter(cnt) ") ";
    }
  }
}

/* ////////////////////////////////////////////////////////////////////////// */
// エラーアイコン
/* ////////////////////////////////////////////////////////////////////////// */
.error-icon {
  width: 96px;
  height: 96px;
  margin-bottom: 34px;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.error-page-message {
  margin-bottom: 40px;
}

/* ////////////////////////////////////////////////////////////////////////// */
// 固定ページ
/* ////////////////////////////////////////////////////////////////////////// */

.fixed-page-content {
  font-size: $font-size-fixed-page-content;
  color: $text-color-dark;

  ol:not(.ordered-list-with-brackets), ul {
    padding-left: $font-size-fixed-page-content;
  }
}

/* ////////////////////////////////////////////////////////////////////////// */
// サイトタイトルのロゴ
/* ////////////////////////////////////////////////////////////////////////// */

.site-logo-pc {
  img {
    width: 240px;
  }

  display: block;
}

.site-logo-sp {
  img {
    width: 160px;
    height: 17px;
  }

  display: none;
}

@media screen and (max-width: $header-collapse-max-width) {
  .site-logo-pc {
    display: none;
  }
  .site-logo-sp {
    display: block;
  }
}

/* ////////////////////////////////////////////////////////////////////////// */
// 必須アイコン
/* ////////////////////////////////////////////////////////////////////////// */
.required-icon {
  position: relative;

  &::after {
    font-style: normal;
    font-weight: 400;
    content: "必須";
    display: inline;
    color: $tertiary-color;
    border: 1px solid $tertiary-color;
    border-radius: 4px;
    position: absolute;
    right: -45px;
    top: -5px;
    padding: 4px;
    font-size: $font-size-pc-input-label;
  }
}

/* ////////////////////////////////////////////////////////////////////////// */
// 強調
/* ////////////////////////////////////////////////////////////////////////// */
.emphasis-secondary {
  color: $secondary-color;
  font-weight: bold;
}