@import "../_colors";
@import "../font";

.page.checkout,
.page.my-page.order-detail {
  .price-list {
    margin-bottom: 24px;

    .price-list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      font-size: $font-size-price-list-big;
      font-weight: normal;

      &.total-price,
      &.number-of-photo {
        font-weight: bold;
        font-size: $font-size-price-list-big;
      }

      &.number-of-photo {
        padding-bottom: 16px;
        margin-bottom: 14px;
        border-bottom: 1px solid $border-color-black-alpha-1;
      }

      &.total-price {
        padding-top: 16px;
        margin-top: 14px;
        border-top: 1px solid $border-color-black-alpha-1;
      }
    }
  }
}