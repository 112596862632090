@font-face {
  font-family: "gjg";
  //noinspection CssUnknownTarget
  src: url("/fonts/GenJyuuGothic-Bold.ttf") format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: "gjg";
  //noinspection CssUnknownTarget
  src: url("/fonts/GenJyuuGothic-Light.ttf") format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: "gjg";
  //noinspection CssUnknownTarget
  src: url("/fonts/GenJyuuGothic-Regular.ttf") format('truetype');
  font-weight: normal;
}