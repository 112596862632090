﻿@use "sass:math";
@import "../_design-constants";

$top-page-design-width: $navbar-max-width;

$tablet-break-point: 767px;
$pc-break-point: 1024px;
$low-resolution-pc-break-point: 1366px;
$high-resolution-pc-break-point: 1921px;

$pc-reference-width: 1920px;
$sp-reference-width: 375px;

$pc-login-form-max-width: 491px;
$sp-login-form-max-width: 320px;

$slides-per-view: 1.5;  // 横幅が1920px以下の場合
$slide-col-number: math.div($slides-per-view, (math.div(($slides-per-view - 1), 2))); // $slides-per-view = 1.5 の場合、6
$left-side-of-main-slide: math.div(100vw, $slide-col-number);

$slide-aspect-ratio: 1.5;
$main-slide-max-width: 1280px;
$main-slide-max-height: math.div($main-slide-max-width, $slide-aspect-ratio);
$main-slide-width: $left-side-of-main-slide * 4;
$main-slide-height: math.div($main-slide-width, $slide-aspect-ratio);