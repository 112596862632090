﻿@use "sass:math";
@import "../_colors";
@import "../_design-constants";
@import "./_top-page-constants";

@mixin top-page-button {
  width: 100%;
  border-radius: 28px;
  font-weight: normal;
  padding: 11px 0;
}

$top-page-navigation-colors: (1: $secondary-color, 2: $primary-color, 3: $tertiary-color-hover, 4: $add-to-favorite-background-color-2);

@each $index, $color in $top-page-navigation-colors {
  .top-page-navigation-buttons {
    li:nth-child(#{$index}) {
      .btn-top-page-navigation,
      .btn-top-page-navigation:focus {
        color: $color;
        background: transparentize(white, 0.5);
        border-color: $color;
      }

      .btn-top-page-navigation:hover,
      .btn-top-page-navigation:not(:disabled):not(.disabled):active {
        color: $input-background-color;
        border-color: $color;
        background: $color;
        opacity: 0.6;
      }


      .btn-top-page-navigation:disabled,
      .btn-top-page-navigation.disabled {
        color: $disabled-color;
        background: white;
        border-color: $disabled-color;
      }
    }
  }
}

.top-page {
  .login-form-row {
    height: $main-slide-height;
    position: absolute;
    top: 0;

    .login-form-section {
      position: relative;
      height: $main-slide-height;

      .login-form-container {
        .login-form {
          width: $pc-login-form-max-width;
          backdrop-filter: blur(10px);
          background: rgb(255, 255, 255, 0.7);
          border-radius: 60px;
          border: none;
          position: absolute;
          top: 20px;
          left: calc(#{$left-side-of-main-slide} + 80px);
          z-index: 1;

          @media screen and (min-width: $high-resolution-pc-break-point) {
            left: calc(50vw - #{math.div($main-slide-max-width, 2)} + 80px);
          }
          
          @media screen and (max-width: $low-resolution-pc-break-point) {
            left: calc(#{$left-side-of-main-slide} + 40px);
          }

          .card-body {
            background: transparent;
            border: none;
            margin: 40px 60px 50px 60px;
            padding: 0;

            //@media screen and (max-width: $top-page-design-width - 1) {
            @media screen and (max-width: $low-resolution-pc-break-point) {
              margin: 15px 35px 20px 35px;
            }

            .catch-copy {
              letter-spacing: -5px;
              font-size: 42px;
              margin-bottom: 14px;
            }

            .caption {
              opacity: 0.7;
              font-size: 13px;
              font-weight: normal;
              line-height: 21px;
              margin-bottom: 14px;
            }

            .top-page-navigation-buttons {
              display: flex;
              flex-direction: column;

              li > .btn {
                @include top-page-button();
                margin-bottom: 12px;
              }
            }

            .guide {
              color: $text-color-dark;
              text-align: center;

              &.password-reminder {
                font-size: 13px;
                margin-bottom: 26px;
              }

              &.registration {
                font-size: 12px;
                margin-bottom: 5px;
              }

              > a, a:hover {
                font-size: 13px;
                font-weight: bold;
                color: $secondary-color;
                text-decoration: none;
              }
            }
            
            .login-id-input-filed {
              font-weight: 300;
              width: 100%;
              height: 48px;
              border-radius: 10px;
              background-color: #EFEFEF;
              border: 1px solid #EFEFEF;
              padding-left: 16px;
              
              &:focus {
                box-shadow: none;
                border-color: $input-outline-color-focus;
              }
              
              &.is-invalid {
                box-shadow: none;
                border-color: $tertiary-color;
              }
            }

            .login-button,
            .register-button {
              @include top-page-button();
              color: $input-background-color;
            }

            .login-button {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $pc-break-point) {
    .login-form-row {
      height: auto;
      position: static;

      .login-form-section {
        position: static;
        height: auto;

        .login-form-container {
          padding-top: 12px;

          .login-form {
            width: 100%;
            max-width: $pc-login-form-max-width;
            background: white;
            position: static;
            margin-left: auto;
            margin-right: auto;

            .card-body {
              .catch-copy {
                text-align: center;
                font-size: 30px;
              }

              .caption {
                font-size: 12px;
                text-align: center;
                margin-bottom: 12px;
              }

              .guide {
                margin-bottom: 7px;
              }

              .password {
                margin-bottom: 13px;
              }

              .login-button {
                margin-bottom: 10px;
              }

              .register-button {
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }
  }
}