﻿@import "../colors";

$sp-max-width: 767.98px;

.account-page {
  @media screen and (max-width: 374px) {
    .col-12 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .account-content-container {
    margin-bottom: 54px;

    @media screen and (max-width: $sp-max-width) {
      margin-bottom: 40px;
    }

    .account-section-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding: 13px 12px 13px 28px;

      @media screen and (max-width: $sp-max-width) {
        margin-bottom: 12px;
      }
    }

    .action-wrapper {
      font-size: 14px;
      font-weight: bold;
      color: $primary-color;
    }

    .icon-wrapper {
      vertical-align: 2px;
      margin-right: 2px;
    }

    .postal-code-row,
    .address-row,
    .name-row,
    .phone-number-row,
    .email-row,
    .password-row {
      font-size: 14px;
      color: black;

      span {
        font-weight: normal;
      }
    }

    .child-information-list {
      .child-information-list-item {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 28px;

        .photo-wrapper {
          img {
            width: 120px;
            height: 120px;
            object-fit: contain;
            border-radius: 10px;
            border: 1px solid $photo-list-border-color;
          }
        }
      }
    }
  }

  &.my-page {
    .child-information {
      .school-info-row,
      .parent-name-row {
        padding-bottom: 5px;
      }
    }
  }
}
