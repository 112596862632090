@import "../colors";
@import "../font";

.page.photo-list {
  .photo-search-status-container {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;

    &.empty {
      display: none;
      margin: 0;
      padding: 0;
    }

    .photo-search-status-title {
      font-weight: bold;
      color: $text-color-dark-alpha;
      margin-bottom: 12px;
      position: relative;
      cursor: pointer;

      .photo-search-status-title-icon {
        display: inline-block;
        transition: 0.5s;
        
        &::after {
          font-weight: bold;
          color: $text-color-dark-alpha;
        }
      }
      &:not(.collapsed) .photo-search-status-title-icon {
        transform: rotate(180deg);
        &::after {
          content: '−';
        }
      }

      &.collapsed .photo-search-status-title-icon {
        transform: rotate(0deg);
        &::after {
          content: '＋';
        }
      }
     
    }


    .photo-search-status-list {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      
      .photo-search-status-list-item {
        margin-right: 12px;
        margin-bottom: 12px;
      }

      &.show {
        display: flex;
      }
      &.collapsing {
        display: flex;
      }
    }
  }
}
