@import "../colors";

.search-by-photo-result-container {
  position: fixed;
  z-index: 1000;
  width: calc(100vw - 2px);
  height: 220px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  bottom: 1px;
  left: 1px;
  box-shadow: 0 0 1px $primary-color;

  padding-left: 16px;
  padding-right: 16px;

  &.relative {
    position: relative;
    margin-bottom: 20px;
  }

  .left-button,
  .right-button {
    position: absolute;
    width: 36px;
    height: 36px;
    top: calc(50% - 18px);
    border-radius: 28px;
    background-color: $primary-color;
    z-index: 1010;
    border: 1px solid $primary-color;
    padding: 0;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .left-button {
    left: 8px;

    &::after {
      //noinspection CssUnknownTarget
      background-image: url("/img/icons/prev.svg");
    }
  }

  .right-button {
    right: 8px;

    &::after {
      //noinspection CssUnknownTarget
      background-image: url("/img/icons/next.svg");
    }
  }

  .search-by-photo-result-list {
    display: flex;
    width: auto;
    height: 220px;
    overflow-x: scroll;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    &::-webkit-scrollbar {
      display: none;
    }

    //noinspection CssUnknownProperty
    scrollbar-width: none;
    //noinspection CssUnknownProperty
    scrollbar-color: transparent;

    .search-by-photo-result-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 220px;
        height: 216px;
        object-fit: contain;
        border: 1px solid $photo-list-border-color;
        border-radius: 10px;
      }
    }
  }
}