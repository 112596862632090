.cart-detail {
  &__modal-dialog {
    max-width: calc(100% - 24px);
    height: calc(100% - 16px);
    margin: 8px auto;
  }

  &__modal-content {
    height: 100%;
  }

  &__header {
    padding: 0;
  }

  &__close-button {
    margin-left: auto;
  }

  &__modal-body {
    padding: 16px;
  }

  &__photo-row {
    height: calc(100% - 82px);
    
    @media screen and (max-width: 834px) {
      //height: calc(100% - 118px);
    }
    //@media screen and (max-width: 669px) {
      //height: calc(100% - 158px);
    //}
    @media screen and (max-width: 674px) {
      height: calc(100% - 118px);
    }
    @media screen and (max-width: 415px) {
      height: calc(100% - 158px);
      //height: calc(100% - 234px);
    }
    @media screen and (max-width: 406px) {
      height: calc(100% - 194px);
      //height: calc(100% - 274px);
    }
    @media screen and (max-width: 347px) {
      //height: calc(100% - 314px);
    }
    width: 100%;
    position: relative;
    margin-bottom: 8px;
    overflow: hidden;
  }

  &__photo-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  &__photo {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}