﻿@use "sass:math";
@import "./_top-page-constants";

.top-page {
  .swiper {
    @media screen and (min-width: 1921px){
      width: $main-slide-max-width;
    }
    
    .swiper-slide {
      transition: 1s;
      transform: scale(0.74);
      
      &.swiper-slide-active {
        transform: scale(1);
      }

      .swiper-slide-item-wrapper {
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 60px 0 60px 0;
        }
      }
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
      }

      .swiper-pagination-bullet-active {
        animation: pagination-bullet-active 0.5s forwards;
      }

      @keyframes pagination-bullet-active {
        0% {
          background: #CECECE;
        }
        100% {
          transform: scale(1.6);
          background: none;
          //noinspection CssUnknownTarget
          content: url("/img/icons/top-image-carousel-indicator.svg");
        }
      }
    }
  }

  .carousel-particles {
    position: absolute;
    top: 0;
    z-index: 1;

    .particle {
      img {
        width: 100%;
        height: auto;
      }
    }
    
    // パーティクルの width、top、left は、
    // PC版(幅:1920px)、SP版(幅:375px)のデザインから
    // 横軸:画面幅、縦軸:パーティクルの width or top or left の一次間数により算定する
    // y = ax + b
    // a: PC版(幅:1920px)、SP版(幅:375px)のパーティクルの width or top or left による傾き
    // x: 画面幅
    // b: 375pxにおけるパーティクルの width or top or left
    @function calcSlope($pc-value, $sp-value) {
      @return math.div(($pc-value - $sp-value), ($pc-reference-width - $sp-reference-width));
    }
    
    .particle-left-blue {
      $pc-particle-width: 46px;
      $sp-particle-width: 44px;
      $pc-top: 5px;
      $sp-top: 21px;
      $pc-offset-x: -34px;
      $sp-offset-x: 18px;
      width: calc(#{calcSlope($pc-particle-width, $sp-particle-width)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-particle-width});
      position: absolute;
      top: calc(#{calcSlope($pc-top, $sp-top)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-top});
      left: calc(#{$left-side-of-main-slide} - (#{calcSlope($pc-particle-width, $sp-particle-width)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-particle-width}) + (#{calcSlope($pc-offset-x, $sp-offset-x)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-offset-x}));
    }

    .particle-left-pink {
      $pc-particle-width: 19px;
      $sp-particle-width: 12px;
      $pc-top: 11px;
      $sp-top: 2px;
      $pc-offset-x: -6px;
      $sp-offset-x: 18px;
      width: calc(#{calcSlope($pc-particle-width, $sp-particle-width)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-particle-width});
      position: absolute;
      top: calc(#{calcSlope($pc-top, $sp-top)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-top});
      left: calc(#{$left-side-of-main-slide} - (#{calcSlope($pc-particle-width, $sp-particle-width)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-particle-width}) + (#{calcSlope($pc-offset-x, $sp-offset-x)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-offset-x}));
    }

    .particle-left-yellow {
      $pc-particle-width: 13px;
      $sp-particle-width: 16px;
      $pc-top: 55px;
      $sp-top: 61px;
      $pc-offset-x: -31px;
      $sp-offset-x: 0px;
      width: calc(#{calcSlope($pc-particle-width, $sp-particle-width)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-particle-width});
      position: absolute;
      top: calc(#{calcSlope($pc-top, $sp-top)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-top});
      left: calc(#{$left-side-of-main-slide} - (#{calcSlope($pc-particle-width, $sp-particle-width)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-particle-width}) + (#{calcSlope($pc-offset-x, $sp-offset-x)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-offset-x}));
    }

    .particle-right-top-yellow {
      $pc-particle-width: 48px;
      $sp-particle-width: 20px;
      $pc-top: 21px;
      $sp-top: 5px;
      $pc-offset-x: 12px;
      $sp-offset-x: 2px;
      width: calc(#{calcSlope($pc-particle-width, $sp-particle-width)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-particle-width});
      position: absolute;
      top: calc(#{calcSlope($pc-top, $sp-top)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-top});
      left: calc(#{$left-side-of-main-slide} * 5 + (#{calcSlope($pc-offset-x, $sp-offset-x)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-offset-x}));
    }

    .particle-right-top-orange {
      $pc-particle-width: 26px;
      $sp-particle-width: 11px;
      $pc-top: 86px;
      $sp-top: 33px;
      $pc-offset-x: -13px;
      $sp-offset-x: -5px;
      width: calc(#{calcSlope($pc-particle-width, $sp-particle-width)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-particle-width});
      position: absolute;
      top: calc(#{calcSlope($pc-top, $sp-top)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-top});
      left: calc(#{$left-side-of-main-slide} * 5 + (#{calcSlope($pc-offset-x, $sp-offset-x)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-offset-x}));
    }

    .particle-right-bottom-blue {
      $pc-particle-width: 48px;
      $sp-particle-width: 15px;
      $pc-top: 126px;
      $sp-top: 42px;
      $pc-offset-x: -41px;
      $sp-offset-x: -6px;
      width: calc(#{calcSlope($pc-particle-width, $sp-particle-width)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-particle-width});
      position: absolute;
      top: calc(#{$main-slide-height} - (#{calcSlope($pc-top, $sp-top)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-top}));
      left: calc(#{$left-side-of-main-slide} * 5 + (#{calcSlope($pc-offset-x, $sp-offset-x)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-offset-x}));
    }

    .particle-right-bottom-pink {
      $pc-particle-width: 87px;
      $sp-particle-width: 19px;
      $pc-top: 41px;
      $sp-top: 20px;
      $pc-offset-x: -24px;
      $sp-offset-x: 2px;
      width: calc(#{calcSlope($pc-particle-width, $sp-particle-width)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-particle-width});
      position: absolute;
      top: calc(#{$main-slide-height} - (#{calcSlope($pc-top, $sp-top)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-top}));
      left: calc(#{$left-side-of-main-slide} * 5 + (#{calcSlope($pc-offset-x, $sp-offset-x)} * (#{$main-slide-width} - #{$sp-reference-width}) + #{$sp-offset-x}));
    }

    @media screen and (min-width: 1921px) {
      .particle-left-blue {
        $particle-width: 46px;
        $offset-x: -34px;
        width: $particle-width;
        position: absolute;
        top: 5px;
        // 50vw - #{math.div($main-slide-max-width, 2)}: メインスライドの左端
        left: calc(50vw - #{math.div($main-slide-max-width, 2)} - #{$particle-width} + #{$offset-x});
      }

      .particle-left-pink {
        $particle-width: 19px;
        $offset-x: -6px;
        width: $particle-width;
        position: absolute;
        top: 11px;
        // 50vw - #{math.div($main-slide-max-width, 2)}: メインスライドの左端
        left: calc(50vw - #{math.div($main-slide-max-width, 2)} - #{$particle-width} + #{$offset-x});
      }

      .particle-left-yellow {
        $particle-width: 13px;
        $offset-x: -31px;
        width: $particle-width;
        position: absolute;
        top: 55px;
        // 50vw - #{math.div($main-slide-max-width, 2)}: メインスライドの左端
        left: calc(50vw - #{math.div($main-slide-max-width, 2)} - #{$particle-width} + #{$offset-x});
      }

      .particle-right-top-yellow {
        $particle-width: 48px;
        $offset-x: 12px;
        width: $particle-width;
        position: absolute;
        top: 21px;
        // 50vw + #{math.div($main-slide-max-width, 2)}: メインスライドの右端
        left: calc(50vw + #{math.div($main-slide-max-width, 2)} + #{$offset-x});
      }

      .particle-right-top-orange {
        $particle-width: 26px;
        $offset-x: -13px;
        width: $particle-width;
        position: absolute;
        top: 86px;
        // 50vw + #{math.div($main-slide-max-width, 2)}: メインスライドの右端
        left: calc(50vw + #{math.div($main-slide-max-width, 2)} + #{$offset-x});
      }

      .particle-right-bottom-blue {
        $pc-particle-width: 48px;
        $offset-x: -41px;
        width: $pc-particle-width;
        position: absolute;
        top: #{$main-slide-max-height - 126px};
        // 50vw + #{math.div($main-slide-max-width, 2)}: メインスライドの右端
        left: calc(50vw + #{math.div($main-slide-max-width, 2)} + #{$offset-x});
      }

      .particle-right-bottom-pink {
        $pc-particle-width: 87px;
        $offset-x: -24px;
        width: $pc-particle-width;
        position: absolute;
        top: #{$main-slide-max-height - 41px};
        // 50vw + #{math.div($main-slide-max-width, 2)}: メインスライドの右端
        left: calc(50vw + #{math.div($main-slide-max-width, 2)} + #{$offset-x});
      }
    }
  }
}