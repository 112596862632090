@import "_colors";
@import "font";

.my-page-title {
  text-align: center;
  margin-bottom: 24px;
  color: $text-color;
}

.my-page-section {
  margin-bottom: 12px;

  .section-header {
    font-size: $font-size-my-page-section-header;
    font-weight: bold;
    
    margin-bottom: 12px;
    padding-bottom: 16px;
    
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.primary {
    .section-header {
      color: $text-color-dark-alpha;
    }
  }

  &.secondary {
    .section-header {
      color: $text-color-dark-alpha;
    }
  }

  .section-body {
    //padding-left: 24px;
    //padding-right: 24px;
    color: $text-color;
  }

  .section-action-container {
    display: flex;
    justify-content: center;
    gap: 12px;

    button, input[type="submit"] {
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 32px;
    }
  }
}