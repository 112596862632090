@import "../colors";

.detail-page-operation {
  display: flex;
  gap: 8px;
  
  &__button {
    background: $primary-color;
    color: white;
    border-radius: 50%;
    padding: 2px;

    &:hover,
    &:not(:disabled):not(.disabled):active {
      opacity: 0.6;
      background: $primary-color;
      color: white;      
    }

    &--left {
    }

    &--right {

    }
  }
}