@import "../_colors";
@import "../design-constants";
@import "../font";

.page.order-history,
.page.order-detail {

  .order-state-label {
    padding: 6px;
    border: 1px solid $order-state-border-color;
    border-radius: 4px;
    color: $order-state-border-color;
    font-weight: bold;
  }

  .order-list-container {
    width: 100%;

    .order-list {
      .order-list-item {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 40px;

        .order-list-item-header-row {
          display: flex;
          flex-direction: column;
          max-width: 100%;

          .section-title {
            margin-bottom: 0;
          }

          color: $text-black-alpha-2;

          .order-header {
            background: $input-background-color;
            font-size: $font-size-cart-property-small-label;
            padding-left: 28px;
            padding-right: 28px;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 14px;
            color: black;

            .order-date {
              margin-right: 53px;
            }

            .order-code {
              margin-right: 53px;
            }

            .download-notes {
              font-weight: bold;
            }
          }
        }

        .order-list-item-bank-account {
          background-color: rgba(51, 191, 67, 0.1);
          border-color: rgba(51, 191, 67, 0.1);
          color: black;
          padding: 20px;
          border-radius: 10px;

          .title {
            font-weight: bold;
          }

          .value {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
          }
        }

        .order-list-item-price-row {
          max-width: 100%;
          color: $text-black-alpha-2;
          font-size: $font-size-cart-property-md;
          font-weight: bold;
          display: flex;
          flex-direction: column;

          .primary-category {
            font-weight: bold;
          }

          .total-amount-and-state {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .total-amount {
              font-weight: bold;
              margin-right: 14px;

              .value {
                font-size: $font-size-cart-property-big;
                color: black;
                font-weight: bold;
              }
            }
          }
        }

        .photo-list {
          max-width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;


          .photo-wrapper {
            border: 1px solid $photo-list-border-color;
            border-radius: 10px;

            margin-right: 12px;

            &:nth-child(4n) {
              margin-right: 0;
            }

            img {
              object-fit: contain;
              $size: calc((min(851px, (100vw - 60px)) - 12px * 3) / 4 - 2px);
              width: $size;
              height: $size;
            }

            .has-more-text {
              display: none;
            }

            &.photo-wrapper-more:last-child {
              position: relative;

              &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.8);
                backdrop-filter: blur(2px);
              }

              .has-more-text {
                display: flex;
                align-items: center;
                justify-content: center;

                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;

                color: $primary-color;
                font-weight: bold;
                font-size: min(3.2vw, 33px);

              }
            }
          }
        }

        .order-summary,
        .photo-property {
          align-self: start;

          .order-summary-item,
          .photo-property-item {
            margin-bottom: 12px;

            .value {
            }

            .label {
              &::after {
                content: '：';
              }
            }
          }
        }

        .order-actions,
        .photo-actions {
          max-width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 13px;
        }
      }
    }
  }
}

// TODO:分けずに統合したほうが分かりやすい
.page.my-page.order-detail {
  .order-summary,
  .shipping-address,
  .shipping-information,
  .payment-method-and-fee,
  .payment-information {
    align-self: start;

    .order-summary-item {
      margin-bottom: 12px;

      .value {
        //font-size: $font-size-lg;
        //font-weight: bold;
      }

      .label {
        &::after {
          content: '：';
        }
      }
    }

  }
}
