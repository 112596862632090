@import "../_colors";
@import "../font";
@import "../design-constants";


.page.photo-list {
  .photo-search-col {
    display: flex;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 28px;
    gap: 7px;

    @media screen and (max-width: $width-xs) {
      padding: 0;
    }
  }

  .photo-list-container {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 52px;

    max-width: 1240px;

    .photo-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-left: auto;
      margin-right: auto;

      .photo-list-item {
        margin-right: 12px;
        margin-bottom: 12px;
        $width-100: min(1240px - 15px * 2, 100vw - 15px * 2 - 15px * 2 - 9px);

        // 一番大きい 4個
        $img-size-4: max(129px, calc((#{$width-100} - 12px * 3) / 4));

        // 1000以下 3個
        $img-size-3: max(129px, calc((#{$width-100} - 12px * 2) / 3));

        // 650以下 2個
        $img-size-2: max(129px, calc((#{$width-100} - 12px * 1) / 2));

        width: $img-size-4;

        &:nth-child(4n) {
          margin-right: 0;
        }

        @media screen and (max-width: 1000px) {
          width: 100%;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:not(:nth-child(3n)) {
            margin-right: auto;
          }
        }

        .photo-container {
          .photo {
            position: relative;
            border: 1px solid $photo-list-border-color;
            border-radius: 10px;
            margin-bottom: 16px;

            img:not(.added-to-cart-banner) {
              object-fit: contain;
              width: calc(#{$img-size-4} - 2px);
              height: calc(#{$img-size-4} - 2px);
              @media screen and (max-width: 1000px) {
                width: 100%;
                height: 80vw;
              }
            }

            img.added-to-cart-banner {
              position: absolute;
              right: 0;
              top: 0;
            }

            .show-photo-detail-button {
              position: absolute;
              bottom: 42px;
              right: 4px;
              z-index: 2;
              background: $input-background-color;
              border-radius: 50%;
              padding: 0 4px 2px 2px;
              margin: 0;
              box-shadow: 0 0 2px $tertiary-color-hover;


              @media screen and (max-width: 529px) {
                //width: 22px;
                //height: 22px;
                padding: 4px 2px 0 4px;
                bottom: 8px;
              }

              .button-icon {
                margin: 0;
                padding: 0;

                img {
                  width: 20px;
                  height: 20px;
                  @media screen and (max-width: 529px) {
                    margin-top: -8px;
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }

          .photo-properties {
            .property-row {
              display: flex;
              justify-content: space-between;
              margin-bottom: 12px;

              &.direction-col {
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 12px;
              }

              .photo-name .value,
              .photo-price .price {
                font-size: $font-size-photo-list-name;
                font-weight: bold;
              }

              .photo-name .value {
                color: black;
              }

              .photo-price {
                .price {
                  color: $primary-color;
                }

                .tax-include {
                  color: black;
                  font-size: $font-size-photo-list-tax-include;
                }
              }

              .label {
                font-size: $font-size-photo-list-select-label;
                color: $text-black-alpha;
                display: block;
              }

              select {
                padding: 8px;
              }

              .photo-count,
              .photo-size {
                .select-wrapper {
                  width: 100px;
                }

                select {
                  width: 100%;
                }
              }
            }
          }

          .add-to-cart {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 8px;
            flex-wrap: wrap;

            .add-to-cart-button {
              padding: 8px 8px;
              font-size: $font-size-photo-list-add-to-cart;
              @media screen and (max-width: 374px) {
                font-size: $font-size-photo-list-add-to-cart-sm;
                padding-left: 6px;
                padding-right: 6px;
              }
            }

            .add-to-favorite-button {
              background: $background-color;

              .add-to-favorite-icon {
                display: inline;
              }

              .remove-from-favorite-icon {
                display: none;
              }

              &.added-to-favorite {
                background: $add-to-favorite-background-color-2;

                .add-to-favorite-icon {
                  display: none;
                }

                .remove-from-favorite-icon {
                  display: inline;
                }
              }

              padding: 6px 0;
              width: 40px;
              @media screen and (max-width: 449px) {
                width: 30px;
              }
              @media screen and (max-width: 374px) {
                padding: 3px 0;
              }

              &:hover,
              &:not(:disabled):not(.disabled):active {
                opacity: 0.6;
              }

              .button-icon {
                margin: 0;
                height: 30px;

                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }

        &.focus {
          animation-name: focused-photo-list-item;
          animation-duration: 5s;
          animation-iteration-count: 1;
        }
      }
    }
  }
}

@keyframes focused-photo-list-item {
  0% {
    box-shadow: 0 0 0 transparent;
  }
  20% {
    box-shadow: 0 0 20px $primary-color;
  }
  75% {
    box-shadow: 0 0 20px $primary-color;
  }
  99% {
    box-shadow: 0 0 0 transparent;
  }
  100% {
    box-shadow: none;
  }
}