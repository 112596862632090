@import "../colors";
@import "../font";

.photo-search-check-actions {
  text-align: right;
  margin-bottom: 24px;

  &__button {
    &, &:hover, &:not(:disabled):not(.disabled):active {
      font-size: $font-size-show-all;
      color: $primary-color;
    }
    
    &--right {
      margin-left: 8px;
    }
  }
}