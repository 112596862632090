@import "../_colors";
@import "../font";

.page.cart {
  .cart-main {

    > .col-12 {
      .cart-item-list-container {
        .cart-list {
          .cart-list-item-per-event {

            .cart-list-item-per-event-content {
              .cart-list-item-event-title {
                a {
                  color: white;
                  font-weight: bold;
                }
              }

              .cart-list-in-event {
                .cart-list-item {
                  .photo-property-item.count {
                    display: flex;
                    flex-direction: column;

                    .label {
                      font-size: $font-size-cart-property-small-label;
                      color: $text-black-alpha-3;
                    }

                    select.value {
                      width: 100%;
                      padding: 8px 22px 8px 10px;
                      font-size: $font-size-cart-property-md;
                      font-weight: bold;
                    }
                  }

                  .photo-actions {
                    .delete-cart-item-button {
                      padding-left: 0;

                      .button-icon {
                        img {
                          width: 18px;
                          height: 18px;
                          margin-top: -3px;
                        }
                      }

                      font-size: $font-size-cart-property-small-label;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//// カートページ
//.page.cart {
//  // カートページ上部のナビゲーション。
//  .cart-nav {
//
//    .sp-toggler {
//      display: none;
//      @media (max-width: 991px) {
//        display: block;
//      }
//      color: $primary-color;
//
//      a {
//        color: $primary-color;
//      }
//
//      a:hover, a:active {
//        text-decoration: none;
//      }
//
//      .collapsed {
//        i.bi.bi-caret-right-fill {
//          transform: rotate(90deg);
//          display: inline-block;
//        }
//      }
//    }
//
//    .cart-nav-list {
//      @media (min-width: 992px) {
//        display: flex;
//      }
//      @media (max-width: 991px) {
//        &:not(.show):not(.collapsing) {
//          display: none;
//        }
//        &.show {
//          display: flex;
//        }
//      }
//
//      justify-content: center;
//      align-content: center;
//      margin-left: auto;
//      margin-right: auto;
//      gap: 12px;
//
//      @media (max-width: 991px) {
//        flex-direction: column;
//      }
//
//      .separator {
//        line-height: 38px;
//        color: $primary-border-color;
//        text-align: center;
//
//        @media (max-width: 991px) {
//          width: 220px;
//          transform: rotate(90deg);
//          line-height: 16px;
//        }
//      }
//
//      .cart-nav-list-item {
//        padding-top: 6px;
//        padding-bottom: 6px;
//        border: 1px solid $primary-border-color;
//        width: 220px;
//        text-align: center;
//
//        &.active {
//          background: $background-color;
//        }
//
//        .cart-nav-link {
//          pointer-events: none;
//          color: $text-color;
//        }
//      }
//    }
//  }
//  
//  .cart-main {
//    
//  }
//}