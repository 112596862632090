@import "../colors";
@import "../font";

.page.exhibition-rooms {
  .front-item-list-main {
    > .col-12 {
      min-height: calc(100vh - 80px - 137px - 370px);
    }
  }
  .front-item-list-item {
    .front-item-list-item-content-body {
      padding: 12px 8px;
    }
  }

  .title-row__event-name {
    color: white;
  }
  
  .title-row, .title-row__period, .title-row__event-name {
    font-weight: bold;
  }
  
  .title-row.small, .title-row__period{
    font-size: $font-size-fixed-page-content;
  }
 
  .title-row-with-delete {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .exhibition-room-message-container {
    padding-bottom: 12px;
    border-bottom: 1px solid $border-color-black-alpha-1;
    margin-bottom: 12px;

    .exhibition-room-message {
      background-color: $primary-background-color-alpha;
      border-color: $primary-background-color-alpha;
      padding: 8px;
      border-radius: 10px;
    }
  }

  .exhibition-room-price-list {
    .price-list-title {

      .section-title {
        font-size: $font-size-exhibition-room-price-title;
        margin-bottom: 12px;
      }
    }

    table {
      width: auto;

      &, th, td {
        border: 1px solid $border-color-black-alpha-1;
      }
      
      th, td {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }

  .price-notes {
    font-size: 14px;
    margin-top: 16px;
  }
}

.page.exhibition-rooms {
  .table-row {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: flex-start;
    justify-content: flex-start;
    
    th {
      text-align: center;
    }
    
    .row-group-header {
      background: $primary-background-color-alpha;
      border-color: $primary-background-color-alpha;
      font-weight: bold;
      text-align: center;
    }
    
    .price-cell {
      color: $primary-color;
      font-weight: bold;
      text-align: right;
    }
  }
}