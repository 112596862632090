@import "colors";
@import "font";
@import "logout-button";

.side-menu-wrapper {
  visibility: collapse;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.1);
  
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(8px);
  transition: 0s;
  left: 0;
  
  &.side-menu-wrapper-left {
    .side-menu {
      left: 0;
    }
  }
  
  &.side-menu-wrapper-right {
    .side-menu {
      right: 0;
    }
  }

  .side-menu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 30;
    top: 0;
    background-color: white;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.08);
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 35px;


    // サイドメニューのヘッダ部分
    .menu-header {
      position: relative;
      .menu-title {
        color: $text-color-dark;
        font-size: $font-size-sp-side-bar-title;
        text-align: center;
        margin-bottom: 52px;

        padding-left: 50px;
        padding-right: 50px;
        
        @supports (overflow-wrap: anywhere) {
          overflow-wrap: anywhere;
        }
      }

      .close-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;

        position: absolute;
        top: -4px;
        right: 24px;
      }
    }

    // リンクのリスト。
    .side-menu-nav-list {
      // リストアイテム
      .side-menu-nav-list-item {

        // リンク
        .side-menu-nav-link {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          text-decoration: none;

          // リンクアイコン。
          .side-menu-nav-link-icon {
            width: 44px;
            height: 44px;
            margin-right: 12px;
          }

          // リンクテキスト部分のラッパー
          .side-menu-nav-link-text-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;

            width: 264px;
            padding-bottom: 22px;
            margin-bottom: 24px;
            border-bottom: 1px solid $menu-border-color;

            // リンクテキスト
            .side-nav-link-text {
              font-size: $font-size-sp-side-bar-link;
              font-weight: 400;
              color: $text-color-dark;
              margin-bottom: 4px;
            }

            // リンクテキストアルファベット部分
            .side-nav-link-alphabet {
              font-size: $font-size-sp-side-bar-link-alphabet;
            }

            &::after {
              content: '';
              position: absolute;
              width: 28px;
              height: 28px;
              right: 24px;
              top: 10px;
              //noinspection CssUnknownTarget
              background: url(/img/icons/caret-right-primary.svg);
            }
          }
        }
      }
    }
    
    .button-container {
      flex-direction: column;
      align-items: center;
      row-gap: 1.5rem;
      margin-top: 1.5rem;
      
      .inquiry-button,
      .logout-button {
        @include logout-button;
      }
    }
  }
}

