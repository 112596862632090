@import "./colors";

.page {
  .child-information {
    width: 100%;

    .child-name-row {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 12px;
      word-break: break-word;

      span {
        font-weight: bold;
      }

      .child-full-name-kana {
        opacity: 0.4;
      }
    }
    
    .child-name-row,
    .school-info-row,
    .parent-name-row {
      .icon-wrapper {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .school-info-row,
    .parent-name-row {
      font-size: 14px;
      font-weight: normal;
      color: black;
      padding-bottom: 16px;
      margin-bottom: 10px;
      border-bottom: 1px solid #E8E8E8;
    }

    .parent-name-row {
      .no-input {
        color: #CECECE;
      }
    }

    .action-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: right;
      gap: 8px;

      .btn-delete-child-info {
        font-size: 14px;
        font-weight: bold;
        color: $tertiary-color;
      }
    }
  }
}

// 注文詳細と、注文内容確認の場合
.page.my-page.order-detail,
.page.checkout.checkout-confirmation {
  .checkout-address {
    .checkout-address-detail.child-information {
      .detail-row {
        &::before {
          display: none;
        }

        .icon-wrapper {
          img {
            width: 24px;
          }
        }

        padding-left: 0;

        &.child-name-row,
        &.school-info-row,
        &.parent-name-row {
          font-size: 15px;
          font-weight: 300;
        }
      }
    }
  }
}