﻿@import "../_colors";
@import "./_top-page-constants";

.top-page {
  .about-section {
    padding-top: 100px;
    padding-bottom: 74px;

    @media screen and (max-width: $tablet-break-point){
      padding-top: 43px;
    }

    .about-contents-container {
      width: 100%;
      height: 600px;
      position: relative;
      //noinspection CssUnknownTarget
      background-image: url("/img/icons/about-background-pc.svg");
      background-repeat: no-repeat;
      background-position-x: center;

      @media screen and (max-width: $tablet-break-point) {
        //noinspection CssUnknownTarget
        background-image: url("/img/icons/about-background-sp.svg");
        background-position-x: center;
      }

      .about-contents {
        padding-top: 85px;

        @media screen and (max-width: $tablet-break-point) {
          padding-top: 80px;
        }

        .section-title-container {
          margin-bottom: 36px;

          .section-title-alphabet {
            font-size: 26px;
            text-align: center;
          }

          .section-title-separator {
            text-align: center;
            height: 2px;
            margin-bottom: 40px;
          }
        }

        .caption-container {
          margin-bottom: 36px;

          .caption {
            width: 100%;
            font-weight: normal;
            color: $text-color-dark;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          }

          .main-caption {
            max-width: 600px;
            font-size: 32px;

            @media screen and (max-width: $tablet-break-point) {
              max-width: 340px;
              font-size: 24px;
              margin-bottom: 30px;
            }

            @media screen and (max-width: 374px) {
              max-width: 290px;
              font-size: 20px;
            }
          }

          .sub-caption {
            max-width: 500px;
            font-size: 18px;

            @media screen and (max-width: $tablet-break-point) {
              font-size: 14px;
            }
          }
        }
      }

      .about-particles {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;

        .particle-image {
          width: 25vw;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .particle-icon {
          width: 10vw;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .particle-image-left {
          max-width: 310px;
          min-width: 115px;

          position: absolute;
          top: 12px;
          left: -19px;
        }

        .particle-icon-left {
          max-width: 92px;
          min-width: 75px;

          position: absolute;
          left: calc(100vw / 1240 * 143);
          bottom: 116px;
        }

        .particle-icon-right {
          max-width: 80px;
          min-width: 47px;

          position: absolute;
          top: 112px;
          right: calc(100vw / 1240 * 178);
        }

        .particle-right {
          max-width: 100px;

          position: absolute;
          top: 69px;
          right: calc(100vw / 1240 * 117);
        }

        .particle-image-right {
          max-width: 292px;
          min-width: 203px;

          position: absolute;
          right: 0;
          bottom: 4px;
        }

        // スマホ版 - 768px未満の場合
        @media screen and (max-width: $tablet-break-point) {
          .particle-image-left {
            top: -25px;
            left: -23px;
          }

          .particle-icon-left {
            left: 16px;
            bottom: 70px;
          }

          .particle-icon-right {
            top: 0;
            right: 0;
          }

          .particle-right {
            display: none;
          }

          .particle-image-right {
            right: 0;
            bottom: -38px;
          }
        }
      }
    }
  }
}