.scale-range {
  display: flex;
  flex-direction: column;

  &__label {
    font-size: 10px;
  }

  &__icon {
    width: 10px;
    height: 10px;
  }
}
