@import "./colors";
.tooltip {
  .tooltip-arrow {
    &::before {
      border-top-color: $title-color-4;
    }
  }
  .tooltip-inner {
    background-color: $title-color-4;
    color: black;
    padding: 4px 12px;
    max-width: 250px;
  }
}