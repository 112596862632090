$primary-color: #00C6CE;
$primary-background-color: #40D350;
$primary-background-color-alpha: rgba(64, 211, 80, 0.1);
$primary-border-color: rgb(255, 185, 113);

$background-color: #FFF8E2;

$add-to-favorite-background-color-2: #FEC833;

$secondary-color: #FF41AF;
$secondary-background-color: #D9F1FF;

$tertiary-color: #FB5853;
$tertiary-color-hover: #FF8E2E;
$tertiary-background-color: rgba(251, 88, 83, 0.1);

$text-color: #717171;
$text-color-dark: #392525;
$text-color-dark-alpha: rgba(57, 37, 37, 0.5);
$text-black-alpha: rgba(0, 0, 0, 0.7);
$text-black-alpha-2: rgba(0, 0, 0, 0.4);
$text-black-alpha-3: rgba(0, 0, 0, 0.5);

$border-color-black: #cccccc;
$border-color-black-alpha-1: rgba(0, 0, 0, 0.1);
$border-color-black-alpha-2: rgba(0, 0, 0, 0.3);
$disabled-color: #D9D9D9;

$input-background-color: #FFFCF2;
$input-outline-color-focus: #20C6CE;

$title-color-normal: #000000;
$title-color-1: $secondary-color;
$title-color-2: $primary-color;
$title-color-3: $tertiary-color-hover;
$title-color-4: #FFC833;
$title-color-5: $tertiary-color;
$title-colors: (normal: $title-color-normal, 1: $title-color-1 , 2: $title-color-2, 3: $title-color-3, 4: $title-color-4);

$menu-border-color: #EDEDED;
$photo-list-border-color: #EFEFEF;


$order-state-border-color: #FD7E23;

$alert-success-background-color: #33BF43;
$alert-success-background-color-alpha: rgb(51, 191, 67, 0.1);
$alert-success-icon-color: #40D350;
