@import "_colors";
@import "design-constants";

.breadcrumb {
  background: transparent;
  font-size: $font-size-sm;
  padding-top: 0;
  max-width: $design-width;
  margin-left: auto;
  margin-right: auto;
  
  .breadcrumb-item {
    &:not(.active) {
      a {
        color: $primary-color;
      }
    }
  }
}