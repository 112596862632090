.cart-photo-cell {
  position: relative;
  &__detail-button {
    background: #FFFCF2;
    position: absolute;
    bottom: 8px;
    right: 8px;
    border-radius: 50%;
    padding: 0 4px 2px 2px;
    margin: 0;
    box-shadow: 0 0 2px #ff8e2e;
  }

  &__detail-button-icon, .button-icon {
    padding: 0;
    margin: 0;
  }

  &__detail-button-icon-image {
    width: 20px;
    height: 20px;
  }
}