﻿@import "../_design-constants";

.face-recognition-notes-dialog {
  .modal-header {
    border-bottom: none;
  }
  
  .notes {
    font-size: 14px;

    * {
      font-weight: normal;
    }

    ol {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: $sp-max-width) {
    padding-right: 0;
    
    .notes {
      font-size: 13px;

      ol {
        padding-inline-start: 25px
      }
    }
  }

  .modal-footer {
    border-top: none;
  }
}