﻿@import "_colors";

$base-color: #EFEFEF;
$input-field-height: 38px;
$input-field-height-for-top: 48px;

.password-input-field-container {
  position: relative;
  border-radius: 10px;

  .password-input-field {
    padding-right: $input-field-height;

    &:focus {
      box-shadow: none;
    }

    &.is-invalid {
      border-color: $tertiary-color;
      padding-right: 65px; // バリデーションエラーアイコン用
      background-position: right $input-field-height center; // バリデーションエラーアイコン用
    }

    &::-ms-reveal {
      display: none;
    }
  }

  .password-input-field-for-top {
    height: $input-field-height-for-top;
    font-weight: 300;
    border-radius: 10px;
    border: 1px solid $base-color;
    background-color: $base-color;
    padding-right: $input-field-height-for-top;

    &:focus {
      background-color: $base-color;
    }

    &.is-invalid {
      border-color: $tertiary-color;
      padding-right: 75px; // バリデーションエラーアイコン用
      background-position: right $input-field-height-for-top center; // バリデーションエラーアイコン用
    }
  }

  .password-mask-icon-wrapper,
  .password-mask-icon-wrapper-for-top {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1px;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .password-mask-icon-wrapper {
    height: $input-field-height;
    width: $input-field-height;

    img {
      margin: 8px;
    }
  }

  .password-mask-icon-wrapper-for-top {
    height: $input-field-height-for-top;
    width: $input-field-height-for-top;
    border-radius: 0 10px 10px 0;
    
    img {
      margin: 13px;
    }
  }
}