﻿@import "../_colors";
@import "../_font";



@mixin prefix-icon($icon-file-path, $base-font-size, $prefix-icon-aspect-ratio: 1) {
  $base-height: 2 * $base-font-size;
  position:absolute;
  left: 0;
  content: '';
  display: inline-block;
  width: $prefix-icon-aspect-ratio * $base-height;
  height: $base-height;
  background: url($icon-file-path) no-repeat center;
  background-size: contain;
}


.section-container {
  margin-bottom: 24px;
  
  .section-body {
    margin-left: 8px;

    $offset: 20px;
    .faq-list {
      padding-left: $offset;
      //noinspection CssUnknownTarget
      list-style-image: url("/img/icons/rhombus.svg");
      list-style-position: outside;
      
      .faq-title {
        font-size: 16px;
        font-weight: normal;
        padding-left: $offset;
        margin-bottom: $font-size-fixed-page-content;
      }
    }

    $prefix-icon-aspect-ratio: 1.4;
    .sub-section-title {
      $base-font-size: 16px;
      font-size: $base-font-size;
      font-weight: normal;
      margin-top: 24px;
      margin-bottom: 16px;

      // アイコン用
      $offset: 2 * $base-font-size;
      $additional-offset: 0.5 * $base-font-size;
      position: relative;
      line-height: $offset;
      padding-left: $prefix-icon-aspect-ratio * $offset + $additional-offset;

      &::before {
        $base-height: 2 * $base-font-size;
        position:absolute;
        left: 0;
        content: '';
        display: inline-block;
        width: $prefix-icon-aspect-ratio * $base-height;
        height: $base-height;
        //noinspection CssUnknownTarget
        background: url("/img/icons/qanda.svg") no-repeat center;
        background-size: contain;
      }
    }

    .faq-container {
      margin-bottom: 8px;
      
      .question {
        color: $text-color-dark;
        text-decoration: none;

        // アイコン用
        $base-font-size: $font-size-fixed-page-content;
        $offset: 2 * $base-font-size;
        $additional-offset: 0.5 * $base-font-size;
        position: relative;
        line-height: $offset;
        padding-left: $prefix-icon-aspect-ratio * $offset + $additional-offset;
        padding-right: $base-font-size;
        
        &::before {
          $base-height: 2 * $font-size-fixed-page-content;
          position:absolute;
          left: 0;
          content: '';
          display: inline-block;
          width: $base-height;
          height: $base-height;
          //noinspection CssUnknownTarget
          background: url("/img/icons/question.svg") no-repeat center;
          background-size: contain;
        }

        &[aria-expanded="false"]::after {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          border-top: 2px solid $border-color-black;
          border-right: 2px solid $border-color-black;
          position: absolute;
          top: 40%;
          right: 0;
          transform: rotate(-45deg);
          transition: transform 0.3s ease;
        }

        &[aria-expanded="true"]::after {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          border-top: 2px solid $border-color-black;
          border-right: 2px solid $border-color-black;
          position: absolute;
          top: 40%;
          right: 0;
          transform: rotate(135deg);
          transition: transform 0.3s ease;
        }
      }

      .answer-container {
        .answer {
          margin-top: 16px;
          
          // アイコン用
          $base-font-size: $font-size-fixed-page-content;
          $offset: 2 * $base-font-size;
          $additional-offset: 0.5 * $base-font-size;
          position: relative;
          line-height: $offset;
          padding-left: $prefix-icon-aspect-ratio * $offset + $additional-offset;

          &::before {
            $base-height: 2 * $font-size-fixed-page-content;
            position:absolute;
            left: 0;
            content: '';
            display: inline-block;
            width: $base-height;
            height: $base-height;
            //noinspection CssUnknownTarget
            background: url("/img/icons/answer.svg") no-repeat center;
            background-size: contain;
          }
          
          .shopping-cart-icon,
          .my-page-icon {
            width: 22px;
            height: 22px;
          }
          
          .menu-icon {
            width: 15px;
            height: 12px
          }
          
          > p {
            margin-bottom: 0.5rem;
          }
          
          > ol {
            list-style: decimal outside none;
            margin-bottom: 0;
          }
          
          > ul {
            list-style: circle outside none;
          }
          
          > ul.asterisk {
            list-style-type: none;
            list-style-position: inside;
            
            li {
              text-indent: -1rem;
              margin-bottom: 0;
              
              &::before {
                content: "※";
                margin-right: 0.5rem;
              }
            }
          }
          
          > ol, ul {
            li {
              margin-bottom: 8px;
            }
          }
          
          span.asterisk {
            &::before {
              content: "※";
              margin-right: 0.5rem;
            }
          }

          div.asterisk {
            padding-left: 1.5rem;
            text-indent: -1.5rem;
            
            &::before {
              content: "※";
              margin-right: 0.5rem;
            }
          }
        }
        
        .rakuten-pay-answer {
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}