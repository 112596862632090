$navbar-max-width: 1240px;
$design-width: 881px;
$header-collapse-max-width: 991px;
$width-xs: 375px;

$sp-max-width: 767px;

$font-size-xl: 28px;
$font-size-lg: 22px;
$font-size-md: 16px;
$font-size-sm: 14px;
$font-size-xs: 12px;