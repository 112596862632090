@import "./_colors";
@import "./_font";
@import "./_margin";
@import "./_design-constants";

header {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;

  .navbar {
    max-width: $navbar-max-width;
    width: 100%;
    height: 60px;
    background-color: transparent !important;

    @media (max-width: $header-collapse-max-width) {
      justify-content: flex-start;
      min-width: 320px;
      padding: 0 12px;
    }

    @media (max-width: $width-xs) {
      padding: 0 6px;
    }

    // コラプス状態では非表示にする。
    &.hidden-if-collapsed {
      @media(max-width: $header-collapse-max-width) {
        display: none;
      }
    }

    // コラプス状態でのみ表示する。
    .hidden-if-expand {
      display: none;
      @media(max-width: $header-collapse-max-width) {
        display: block;
      }
    }

    .navbar-toggler {
      border: none;
      margin-right: 0;
    }

    .navbar-brand {
      font-weight: bold;
      font-size: $font-size-pc-nav-brand;
      margin-left: 10px;

      @media (max-width: $header-collapse-max-width) {
        font-size: $font-size-sp-nav-brand;
        margin-right: auto;
      }

      @media (max-width: $width-xs) {
        margin-left: 6px;
      }
    }


    // コラプス状態のアイコンのみリンク。
    .nav-link-sm {
      margin: 0 10px;

      @media (max-width: $width-xs) {
        margin: 0 6px;
      }

      img {
        width: 22px;
        height: 22px;
      }

      &.navbar-toggler {
        padding: 0;
      }
    }


    .navbar-cart {
      margin-left: auto;
      margin-right: 6px;
      background: none;
      border: none;
      padding: 0;
      position: relative;

      .bi-cart {
        font-size: 34px;
      }

      .badge {
        position: absolute;
        background: #FF8200;
        left: 9px;
        top: -1px;
        color: white;
        font-size: 12px;
      }
    }


    // 各navリンク
    .navbar-nav {
      //height: 60px;

      .nav-item {
        .nav-link {
          span {
            color: $text-color-dark;
            font-weight: 400;
            font-size: $font-size-pc-nav-link;
          }
        }
      }

      // ログインしていない場合のナビゲーションリンク
      &.nav-link-list-not-signed {
        margin-left: auto;
        margin-right: $margin-md;

        .nav-item {
          margin-right: 48px;
        }
      }

      &.nav-link-list-signed-in {
        margin-left: auto;
        margin-right: $margin-md;

        .nav-item {
          margin-right: 4px;
        }
      }

      // アイコン付きのリンク
      &.nav-link-icon-link-list {
        .nav-link.icon-link {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 0;
          padding-bottom: 0;

          .icon-image {
            width: 32px;
            height: 32px;
          }

          .icon-label {
            font-size: $font-size-pc-icon-label;
          }
        }
      }
    }

    // お問い合わせボタン
    .inquiry-button {
      border-radius: 40px;
    }

    .hidden-if-expand.inquiry-button {
      padding: 12px 14px;

      .button-icon {
        margin: 0;
      }
    }

    .navbar-collapse {
      .inquiry-button {
        padding: 17px 41px;
      }
    }

    // アカウント名とカート部分。
    .actions {
      > a {
        color: $text-color;
      }
    }

  }
}