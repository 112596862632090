@import "../_colors";
@import "../font";

// 注文・お届け方法
.page.checkout-shipping {

  .shipping-method-radio-label {
    .shipping-method-name,
    .shipping-fee {
      font-size: $font-size-radio-label;
    }

    .shipping-method-name {
      display: inline-block;
      width: 120px;
    }
  }

  .download-checkbox-label {
    .label {
      margin-right: 12px;
      @media screen and (max-width: 345px){
        margin-right: 4px;
      }
    }
    .label,
    .fee {
      font-size: $font-size-radio-label;
      @media screen and (max-width: 429px){
        font-size: calc(#{$font-size-radio-label} - 1px);
      }
      @media screen and (max-width: 389px){
        font-size: calc(#{$font-size-radio-label} - 2px);
      }
      @media screen and (max-width: 364px){
        font-size: calc(#{$font-size-radio-label} - 3px);
      }
      @media screen and (max-width: 345px){
        font-size: calc(#{$font-size-radio-label} - 4px);
      }

      @media screen and (max-width: 329px){
        font-size: calc(#{$font-size-radio-label} - 5px);
      }

      font-weight: normal;
    }
  }

  input[type=checkbox]:checked ~ .download-checkbox-label .label,
  input[type=checkbox]:checked ~ .download-checkbox-label .fee {
    font-weight: bold;
  }

  .shipping-fee-unit {
    font-size: $font-size-radio-description;
    color: $text-color;
    padding-left: 24px;
  }

  .checkbox-label.force-checked,
  input[type=checkbox].force-checked {
    pointer-events: none;
  }
}