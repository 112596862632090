@import "_colors";
@import "./design-constants";
@import "./font";

footer {
  background: $background-color;
  color: $text-color-dark;
  width: 100%;

  > .row {
    margin: 0;

    > .col-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.container-fluid {
    padding: 0;
    margin: 0;
  }

  .site-logo.row {
    .col-12 {
      text-align: center;
    }

    margin-top: 78px;

    .site-logo-pc {
      font-size: 42px;
      margin-bottom: 42px;
    }

    @media screen and (max-width: $header-collapse-max-width) {
      margin-top: 55px;
      margin-bottom: 48px;
    }
  }

  .row {
    .site-logo {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > img {
        width: 38px;
      }
    }

    .footer-links {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      font-size: $font-size-footer-link-pc;
      column-gap: 48px;

      a {
        text-decoration: none;
        color: $text-color-dark;
      }

      @media screen and (max-width: $header-collapse-max-width) {

        flex-direction: column;
        font-size: $font-size-footer-link-sp;
        li:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }

    .payment-banners {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;
      margin-top: 32px;
      margin-bottom: 32px;
      padding-left: 24px;
      padding-right: 24px;
      
      img {
        max-width: 100%;
      }
    }

    .copy-right {
      text-align: center;
      font-size: $font-size-copy-right-pc;
      color: $text-color-dark-alpha;
      margin-bottom: 100px;
      margin-top: 48px;

      @media screen and (max-width: $header-collapse-max-width) {
        margin-top: 32px;
        margin-bottom: 44px;
        font-size: $font-size-copy-right-sp;
      }
    }


  }

  position: absolute;
  bottom: 0;

  padding-top: 24px;
}