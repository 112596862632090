@import "../design-constants";

.page.checkout-confirmation {
  .price-list {
    .price-list-item.regulation-list__item {
      gap: 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      @media screen and (max-width: $sp-max-width) {
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
      }
    }

    .regulation-list-item-title {
      width: 120px;
      padding-bottom: 8px;
      @media screen and (max-width: $sp-max-width) {
        width: auto;
        flex-grow: 1;
      }
    }

    .regulation-list-item-body {
      flex-grow: 1;
      padding-bottom: 8px;
      @media screen and (max-width: $sp-max-width) {
        padding-left: 12px;
      }
    }

    .regulation-list-payment-title {
      margin-bottom: 8px;
    }

    .regulation-list-payment-body {
      padding-left: 12px;
      margin-bottom: 18px;
    }
  }
}