﻿@import "./_colors";
@import "./_design-constants";

.to-top-and-bottom-button {
  position: sticky;
  z-index: 20;
  height: 0;
  max-width: $navbar-max-width;
  padding: 0 12px;
  margin-left: calc((100% - #{$navbar-max-width}) / 2);
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: $navbar-max-width) {
    margin-left: 0;
  }
  
  &.to-bottom-button {
    top: 20px;
  }
  
  &.to-top-button {
    top: calc(100% - 20px);
  }
  
  &-button {
    width: 60px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-button.to-top-button-button {
    position: absolute;
    bottom: 0;
  }

  &-icon {
    width: 40px;
    height: 40px;
    padding-bottom: 8px;
    margin-bottom: 9px;
    padding-top: 8px;
    border-radius: 30px;
    background: $secondary-color;
    box-shadow: 0 3px 20px #0000001A;

    i {
      width: 24px;
      height: 24px;
    }
  }

  &-caption {
    font-size: $font-size-sm;
    font-weight: normal;
    color: $secondary-color;
  }
}

.destination-for-to-bottom-button {
  height: 100vh;
  margin-top: -100vh;
}