.blocking-copy * {
  pointer-events: none !important;
}

.block-copy {
  img {
    pointer-events: none;
    user-select: none;
  }
  .photo-width {
    user-select: none;
  }
}
