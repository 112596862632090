@import "../_colors";
@import "../font";

.photo-detail-modal {
  width: 100%;
  height: 100%;

  &__dialog {
    max-width: calc(100% - 24px);
    height: max(400px, calc(100% - 16px));

    margin: 8px auto;
  }

  &__content {
    height: 100%;
  }

  &__header {
    padding: 0;
  }

  &__close-button {
    margin-left: auto;
  }

  &__body {
    padding: 16px;
  }

  &__photo-row {
    height: calc(100% - 82px);
    @media screen and (max-width: 834px) {
      height: calc(100% - 118px);
    }
    @media screen and (max-width: 705px) {
      height: calc(100% - 158px);
    }
    @media screen and (max-width: 535px) {
      height: calc(100% - 194px);
    }
    @media screen and (max-width: 444px) {
      height: calc(100% - 234px);
    }
    @media screen and (max-width: 347px) {
      height: calc(100% - 274px);
    }
    @media screen and (max-width: 347px) {
      height: calc(100% - 314px);
    }
    width: 100%;
    position: relative;
    margin-bottom: 8px;
  }

  &__photo-container {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  &__photo-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  &__photo {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__added-to-cart-banner {
    position: absolute;
    top: 0;
    right: 0;
    width: 30vw;
    height: 30vw;
    max-width: 300px;
    max-height: 300px;
    min-width: 150px;
    min-height: 150px;
    pointer-events: none;
    background: transparent;
  }
}

//.modal.photo-detail {
//  .modal-dialog {
//
//    .modal-content {
//      overflow: auto;
//
//      max-width: 881px;
//      padding-left: 16px;
//      padding-right: 16px;
//      margin-left: auto;
//      margin-right: auto;
//      border: 0;
//
//      @media (max-height: 486px) {
//        padding-left: 32px;
//        padding-right: 32px;
//      }
//
//      background: white;
//      backdrop-filter: blur(10px);
//      height: 100vh;
//
//      .modal-header {
//        padding-left: 0;
//        padding-right: 0;
//        padding-bottom: 24px;
//        border-bottom: 0;
//
//        @media screen and (max-width: 391px) {
//          padding-bottom: 12px;
//        }
//      }
//
//      .modal-body {
//        padding: 0;
//
//        .paging-row {
//          display: flex;
//          justify-content: center;
//
//          .photo-name {
//            color: white;
//          }
//        }
//
//        .photo-name-and-price-row {
//          display: flex;
//          justify-content: center;
//          gap: 12px;
//          flex-direction: row;
//          flex-wrap: wrap;
//
//          .photo-name {
//            width: auto;
//            min-width: 132px;
//            max-width: 100%;
//            white-space: normal;
//            word-break: break-all;
//          }
//
//          .price-label {
//            width: auto;
//            min-width: 132px;
//            max-width: 100%;
//            text-align: right;
//            white-space: normal;
//            word-break: break-all;
//          }
//        }
//
//        .size-count-row {
//          display: flex;
//          flex-wrap: wrap;
//          gap: 12px;
//          margin-bottom: 12px;
//          justify-content: center;
//
//          .spss-form-group {
//            display: flex;
//            justify-content: center;
//            align-items: center;
//            gap: 8px;
//            margin-bottom: 0;
//
//            label {
//              margin-bottom: 0;
//              font-size: $font-size-photo-list-select-label;
//            }
//
//            .select-wrapper {
//              width: 100px;
//
//              select {
//                font-size: $font-size-photo-list-select-label;
//                width: 100%;
//                padding: 8px;
//                font-weight: normal;
//              }
//            }
//          }
//        }
//
//        .photo-row {
//
//          .photo-container {
//            text-align: center;
//            overflow: hidden;
//            border: 1px solid $disabled-color;
//
//
//            .photo-wrapper {
//              text-align: center;
//              overflow: hidden;
//              position: relative;
//
//              img:not(.added-to-cart-banner) {
//                object-fit: contain;
//                $y-margin: 420px;
//                height: unquote('calc(max(100vh, 800px) - #{$y-margin})');
//                width: 100%;
//              }
//
//              img.added-to-cart-banner {
//                position: absolute;
//                top: 0;
//                right: 0;
//                width: 30vw;
//                height: 30vw;
//                max-width: 300px;
//                max-height: 300px;
//                min-width: 150px;
//                min-height: 150px;
//                pointer-events: none;
//                background: transparent;
//              }
//            }
//          }
//        }
//
//        .photo-name {
//          color: $text-color-dark;
//          font-weight: bold;
//        }
//
//        .price-label {
//          color: $primary-color;
//          font-size: $font-size-photo-detail-price;
//          font-weight: bold;
//
//          * {
//            font-weight: bold;
//          }
//        }
//
//        .operation-row {
//          display: flex;
//          justify-content: center;
//          align-items: center;
//          flex-wrap: wrap;
//          margin-bottom: 12px;
//          margin-top: 12px;
//
//          .scale-operations {
//            display: flex;
//            flex-wrap: wrap;
//            align-items: center;
//            justify-content: center;
//
//            .spss-form-group {
//              margin-bottom: 0;
//              display: flex;
//              flex-direction: column;
//              align-items: center;
//              gap: 3px;
//
//              .form-control-range {
//                width: 140px;
//                margin-bottom: 0;
//              }
//
//              label {
//                margin-bottom: 0;
//                font-size: 10px;
//                color: $text-color-dark;
//                font-weight: normal;
//              }
//            }
//
//            .reset-scale-button {
//              color: $secondary-color;
//              font-size: $font-size-input;
//            }
//          }
//
//          .page-operations {
//            button {
//              background: $primary-color;
//              padding: 2px;
//              border-radius: 28px;
//            }
//          }
//        }
//
//        .cart-row {
//          display: flex;
//          align-items: center;
//          justify-content: center;
//          flex-direction: column;
//          gap: 8px;
//
//          & > .btn {
//            margin-bottom: 8px;
//          }
//
//          .add-to-favorite-button {
//            padding-top: 10px;
//            padding-bottom: 10px;
//            max-width: 252px;
//            width: 100%;
//            border-radius: 22px;
//
//            .favorite-button-text {
//              font-weight: 400;
//            }
//
//            .icon-image.add {
//              display: inline;
//            }
//
//            .icon-image.remove {
//              display: none;
//            }
//
//            &.added-to-favorite {
//              .icon-image.add {
//                display: none;
//              }
//
//              .icon-image.remove {
//                display: inline;
//              }
//            }
//          }
//
//          .add-to-cart-button {
//            padding-top: 10px;
//            padding-bottom: 10px;
//            max-width: 252px;
//            width: 100%;
//            border-radius: 28px;
//          }
//
//          .close-button {
//            color: $text-black-alpha-2;
//          }
//        }
//      }
//    }
//  }
//}