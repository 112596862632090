@use "sass:math";
@import "colors";
@import "font";
@import "logout-button";

.nav-item-my-page {
  &:hover {
    .my-page-menu {
      display: flex;
    }
  }
  .my-page-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;
    border-radius: 30px;
    background: white;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.15);
    z-index: 30;
    max-width: 286px;
    
    $my-page-menu-padding-left: 30px;
    padding: 32px 0 $my-page-menu-padding-left 30px;

    .my-page-menu-header {
      width: 100%;
      text-align: center;
      position: relative;
      margin-bottom: 20px;
      padding-right: $my-page-menu-padding-left;
      .menu-title {
        font-size: 16px;
        word-break: break-word;
        color: $text-color-dark;
        &, & * {
          font-weight: normal;
        }
      }
    }
    
    .logout-button {
      @include logout-button;
      margin-left: -30px;
    }
  }

  // リンクのリスト。
  .my-page-menu-nav-list {
    // リストアイテム
    .my-page-menu-nav-list-item {

      // リンク
      .my-page-menu-nav-link {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        text-decoration: none;

        // リンクアイコン。
        .my-page-menu-nav-link-icon {
          width: 44px;
          height: 44px;
          margin-right: 12px;
        }

        // リンクテキスト部分のラッパー
        .my-page-menu-nav-link-text-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;

          width: 200px;
          padding-bottom: 22px;
          margin-bottom: 24px;
          border-bottom: 1px solid $menu-border-color;

          // リンクテキスト
          .my-page-menu-link-text {
            font-size: $font-size-sp-side-bar-link;
            font-weight: 400;
            color: $text-color-dark;
            margin-bottom: 4px;
          }

          // リンクテキストアルファベット部分
          .my-page-menu-link-alphabet {
            font-size: $font-size-sp-side-bar-link-alphabet;
          }

          &::after {
            content: '';
            position: absolute;
            width: 28px;
            height: 28px;
            right: 24px;
            top: 10px;
            //noinspection CssUnknownTarget
            background: url(/img/icons/caret-right-primary.svg);
          }
        }
      }
    }
  }
}