@import "./colors";
@import "./font";
@import "./design-constants";

// カート画面と注文画面内のカート項目リスト

.page.cart,
.page.checkout,
.page.my-page.order-detail {
  .cart-item-list-container {

    .cart-list {
      display: flex;
      flex-direction: column;

      .cart-list-item-per-event {
        .cart-list-item-per-event-content {
          .cart-list-item-to-checkout {
            margin-bottom: 24px;
          }

          .cart-list-item-header {
            border-bottom: 1px solid $border-color-black-alpha-1;
            margin: 16px 0 20px;
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 16px;

            display: flex;
            justify-content: space-between;

            .primary-category-and-subtotal {
              padding-right: 4px;

              .primary-category {
                font-size: 20px;
                font-weight: bold;
                color: $text-black-alpha-2;
                margin-bottom: 12px;

                @media screen and (max-width: 374px) {
                  font-size: 18px;
                }
              }

              .subtotal {
                display: flex;
                flex-wrap: wrap;
                font-size: $font-size-cart-primary-category;
                font-weight: bold;
                color: $text-black-alpha-2;
                margin-bottom: 12px;

                .label,
                .separator,
                .value {
                  &, & .value-inner {
                    font-weight: bold;
                    font-size: 20px;

                    @media screen and (max-width: 374px) {
                      font-size: 18px;
                    }
                  }
                }

                .value {
                  color: $primary-color;
                }

                .tax-include {
                  font-size: 12px;
                  font-weight: normal;
                  color: black;
                }
              }
            }
          }

          .cart-list-in-event {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-left: 24px;
            margin-right: 24px;
            @media screen and (max-width: 549px) {
              margin-left: 12px;
              margin-right: 12px;
            }

            .cart-list-item-padding {
              width: 200px;
              height: 200px;
              @media screen and (max-width: 724px) {
                width: 180px;
                height: 180px;
              }
              @media screen and (max-width: 699px) {
                width: 160px;
                height: 160px;
              }
              @media screen and (max-width: 674px) {
                width: 140px;
                height: 140px;
              }
              @media screen and (max-width: 649px) {
                width: 120px;
                height: 120px;
              }
              @media screen and (max-width: 624px) {
                width: 120px;
                height: 120px;
              }
              @media screen and (max-width: 599px) {
                width: 120px;
                height: 120px;
              }

              @media screen and (max-width: 574px) {
                width: 120px;
                height: 120px;
              }

              @media screen and (max-width: 549px) {
                width: 120px;
                height: 120px;
              }

              @media screen and (max-width: 499px) {
                width: 120px;
                height: 120px;
              }
              @media screen and (max-width: 474px) {
                width: 120px;
                height: 120px;
              }
              @media screen and (max-width: 449px) {
                width: 120px;
                height: 120px;
              }
              @media screen and (max-width: 424px) {
                width: 120px;
                height: 120px;
              }
              @media screen and (max-width: 399px) {
                width: 120px;
                height: 120px;
              }
              @media screen and (max-width: 374px) {
                width: 120px;
                height: 120px;
              }
            }
            .cart-list-item {
              display: flex;
              flex-direction: column;
              gap: 24px;
              @media screen and (max-width: 750px) {
                gap: 22px;
              }
              @media screen and (max-width: 700px) {
                gap: 20px;
              }
              @media screen and (max-width: 600px) {
                gap: 18px;
              }
              @media screen and (max-width: 549px) {
                gap: 16px;
              }
              @media screen and (max-width: 480px) {
                gap: 12px;
              }

              align-items: center;

              margin-bottom: 1rem;
              padding-bottom: 1rem;
              padding-top: 1rem;

              .photo-wrapper {
                img {
                  width: 200px;
                  height: 200px;
                  @media screen and (max-width: 724px) {
                    width: 180px;
                    height: 180px;
                  }
                  @media screen and (max-width: 699px) {
                    width: 160px;
                    height: 160px;
                  }
                  @media screen and (max-width: 674px) {
                    width: 140px;
                    height: 140px;
                  }
                  @media screen and (max-width: 649px) {
                    width: 120px;
                    height: 120px;
                  }
                  @media screen and (max-width: 624px) {
                    width: 120px;
                    height: 120px;
                  }
                  @media screen and (max-width: 599px) {
                    width: 120px;
                    height: 120px;
                  }

                  @media screen and (max-width: 574px) {
                    width: 120px;
                    height: 120px;
                  }

                  @media screen and (max-width: 549px) {
                    width: 120px;
                    height: 120px;
                  }

                  @media screen and (max-width: 499px) {
                    width: 120px;
                    height: 120px;
                  }
                  @media screen and (max-width: 474px) {
                    width: 120px;
                    height: 120px;
                  }
                  @media screen and (max-width: 449px) {
                    width: 120px;
                    height: 120px;
                  }
                  @media screen and (max-width: 424px) {
                    width: 120px;
                    height: 120px;
                  }
                  @media screen and (max-width: 399px) {
                    width: 120px;
                    height: 120px;
                  }
                  @media screen and (max-width: 374px) {
                    width: 120px;
                    height: 120px;
                  }
                  object-fit: contain;
                  border: 1px solid $photo-list-border-color;
                  border-radius: 10px
                }
              }

              .photo-wrapper-select-for-download {

              }

              .photo-property {
                width: 100%;

                .property-row {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  padding-bottom: 12px;
                  margin-bottom: 10px;
                  border-bottom: 1px solid $border-color-black-alpha-1;


                  .photo-property-item.file-number,
                  .photo-property-item.file-number .value,
                  .photo-property-item.price,
                  .photo-property-item.price .unit,
                  .photo-property-item.price .price {
                    font-weight: bold;
                    font-size: $font-size-cart-property-big;
                    margin-bottom: 0;
                  }

                  .photo-property-item.price {
                    color: $primary-color;

                    .tax {
                      color: black;
                      font-weight: normal;
                      font-size: $font-size-photo-list-tax-include;
                    }
                  }
                }

                .property-row-select-for-download {
                  margin-bottom: 0;
                  border: none;
                }

                .photo-property-item.size,
                .photo-property-item.count {
                  display: flex;
                  flex-direction: column;

                  .label {
                    font-size: $font-size-cart-property-small-label;
                    color: $text-black-alpha-3;
                  }

                  .value {
                    font-size: $font-size-cart-property-md;
                    font-weight: bold;
                  }
                }

                .photo-property-item {
                  margin-bottom: 12px;
                }


                .photo-actions {
                }
              }

            }
          }

          .checkout-button-container {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 12px;

            .go-to-checkout {
              padding-top: 7px;
              padding-bottom: 7px;
              width: 108px;
            }
          }

          .cart-list-item-footer {
            padding: 16px;

            .checkout-button-container {
              justify-content: space-between;
              align-items: center;
              flex-direction: row;
            }
          }
        }
      }
    }
  }
}