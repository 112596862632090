/* カート、展示室一覧など背景がグレーの項目一覧系のデザイン。
 */

@import "../_colors";
@import "../font";

.front-item-list-main {
  background: $menu-border-color;

  .front-item-list-container {
    .front-item-list {
      .front-item-list-item {
        margin-top: 20px;
        margin-bottom: 28px;
        background: white;
        border-radius: 10px;

        .front-item-list-item-content {
          .front-item-list-item-content-title {
            background: $primary-color;
            color: white;
            font-size: $font-size-cart-list-event-title;
            border-radius: 10px 10px 0 0;
            padding: 16px 24px;
            font-weight: bold;
          }
        }
      }
    }
  }
}