﻿.page.checkout,
.page.thanks,
.page.my-page,
.page.password-reset {
  .action-row {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .btn-primary {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 24px;
        height: 24px;
        right: 24px;

        //noinspection CssUnknownTarget
        background-image: url("/img/icons/arrow-next-yellow.svg");
      }
    }

    .btn-outline-gray,
    .btn-text-gray {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 24px;
        height: 24px;
        left: 16px;

        //noinspection CssUnknownTarget
        background-image: url("/img/icons/arrow-return-left-gray.svg");
      }
    }

    .pseudo-none {
      &::before,
      &::after {
        content: none;
      }
    }
  } 
}