@import "_colors";

.my-page-nav {
  .nav {
    display: flex;
    justify-content: center;
    gap: 12px;

    @media (max-width: 720px) {
      flex-direction: column;
      text-align: center;
    }

    .nav-item {
      .nav-link {
        border: 1px solid $primary-color;
        border-radius: 36px;
        color: $primary-color;

        &.active {
          background: $background-color;
        }
      }
    }
  }

  @media (max-width: 720px) {
    &:not(.show) {
      display: none;
    }
  }
}

.my-page-title-wrapper {
  .dummy-link {
    pointer-events: none;
  }

  .accordion-toggler {
    display: none;

    .btn {
      font-size: 28px;
    }

    &.show {
      .bi-caret-down-fill::before {
        transform: rotate(180deg);
      }
    }
  }
}

@media (min-width: 721px) {
  .my-page-nav {
    &:not(.show) {
      display: block;
    }
  }
}

@media (max-width: 720px) {
  .my-page-title-wrapper {
    display: flex;
    align-items: center;

    .dummy-link {
      pointer-events: all;
      cursor: pointer;
    }

    .accordion-toggler {
      display: inline-block;
    }

    .my-page-nav {
      &:not(.show) {
        display: none;
      }
    }
  }
}