﻿@import "../_colors";
@import "../_design-constants";

.page.how-to-use {
  .page-title-container {
    position: relative;
    height: 150px;
  
    .title-accent-left-pc-wrapper {
      display: inline-block;
      position: absolute;
      top: -45px;
      right: 400px;
      bottom: 0;
      left: 0;
      margin: auto;
  
      width: fit-content;
      height: fit-content;
  
      > img {
        width: 216px;
        height: 138px;
      }
    }
  
    .title-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
  
      .title-accent-left-sp-wrapper {
        display: none;
      }
    }
  
    .title-accent-right-wrapper {
      display: inline-block;
      position: absolute;
      top: -45px;
      right: 0;
      bottom: 0;
      left: 250px;
      margin: auto;
      transform: rotate(9deg);
  
      width: fit-content;
      height: fit-content;
  
      > img {
        width: 67px;
        height: 79px;
      }
    }
  }
  
  @media screen and (max-width: 768px){
    .page-title-container {
      height: 200px;
  
      .title-accent-left-pc-wrapper {
        display: none;
      }
  
      .title-wrapper {
        .title-accent-left-sp-wrapper {
          display: block;
          position: relative;
          height: 76px;
          margin-top: 20px;
          margin-bottom: 15px;
  
          > img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 167px;
            height: 76px;
          }
        }
      }
  
      .title-accent-right-wrapper {
        display: inline-block;
        position: absolute;
        top: auto;
        right: 0;
        bottom: 25px;
        left: 200px;
        margin: auto;
        transform: rotate(18deg);
  
        > img {
          width: 39px;
          height: 46px;
        }
      }
    }
  }
  
  .step-color-1 {
    background: $title-color-1;
  }
  
  .step-color-2 {
    background: $title-color-2;
  }
  
  .step-color-3 {
    background: $title-color-3;
  }
  
  .step-color-4 {
    background: $title-color-4;
  }
  
  .how-to-use-container {
    max-width: $navbar-max-width;
    height: 800px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  
    .accent {
      position: absolute;
      top: 10px;
      width: 100%;
      text-align: center;
    }
  
    .main-content {
      position: absolute;
      width: 100%;
  
      .steps-container {
  
        .step-1-container {
          margin-top: 30px;
        }
  
        .step-3-container {
          margin-top: 57px;
        }
  
        .step-4-container {
          margin-top: 10px;
        }
  
        .step-1-container,
        .step-2-container,
        .step-3-container,
        .step-4-container {
          display: flex;
          align-items: center;
          flex-direction: column;
  
          .step-3-number-accent-wrapper,
          .step-4-number-accent-wrapper {
            > i > img {
              width: 29px;
              height: 10px;
            }
          }
  
          .step-number {
            display: inline-block;
            width: 60px;
            height: 60px;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            color: #FFFCF2;
            border-radius: 50%;
            padding-top: 12px;
          }
  
          .step-caption {
            font-size: 20px;
            font-weight: bold;
            margin-top: 24px;
            margin-bottom: 32px;
          }
  
          .step-image-wrapper {
            img {
              width: 260px;
              height: auto;
              border: 2px solid #F2F2F2;
              border-radius: 35px;
            }
          }
  
          .step-4-notes {
            font-size: 12px;
            font-weight: 400;
            margin-top: 16px;
          }
        }
      }
    }
  
    @media screen and (max-width: 1200px) {
      height: auto;
      position: static;
  
      .main-content {
        position: static;
  
        .row {
          align-items: baseline;
          row-gap: 50px;
        }
  
        .steps-container {
  
          .step-1-container,
          .step-2-container,
          .step-3-container,
          .step-4-container {
            margin-top: 0;
          }
  
          .step-next-icon-wrapper {
            margin: 20px 0;
  
            > i > img {
              width: 15px;
              height: 12px;
            }
          }
        }
      }
    }
  
    @media screen and (max-width: 768px) {
      .main-content {
        .row {
          align-items: start;
          row-gap: 0;
        }
  
        .steps-container {
  
          .step-1-container,
          .step-2-container,
          .step-3-container,
          .step-4-container {
            .step-number {
              width: 48px;
              height: 48px;
              padding-top: 6px;
            }
  
            .step-caption {
              margin-top: 22px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}