﻿@import "../_colors";
@import "../_design-constants";

.page.how-to-use {
  .point-color-1 {
    color: $title-color-1;
  }

  .point-color-2 {
    color: $title-color-2;
  }

  .point-color-3 {
    color: $title-color-3;
  }

  .point-color-4 {
    color: $title-color-4;
  }

  .point-section-title-row,
  .point-section-row {
    background: $background-color;
  }

  .point-section-title-row {
    padding-top: 80px;
    padding-bottom: 25px;

    @media screen and (max-width: 1200px) {
      margin-top: 100px;
    }

    @media screen and (max-width: 992px) {
      padding-bottom: 0;
    }
  }

  .point-section-row {
    padding-bottom: 60px;

    .point-content-row {
      max-width: 1000px;

      .col-6:nth-child(odd) {
        .point-content-container {
          margin-right: auto;
        }
      }

      .col-6:nth-child(even) {
        .point-content-container {
          margin-left: auto;
        }
      }

      .point-content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 480px;
        height: 348px;
        background: white;
        border-radius: 40px;
        margin-top: 70px;

        position: relative;
        
        @media screen and (max-width: 576px){
          max-width: 100%;
        }

        .point-number-wrapper {
          position: absolute;
          left: 33px;
          top: -40px;

          display: flex;
          flex-direction: column;

          .point-prefix {
            font-size: 21px;
            font-weight: normal;
            line-height: 21px;
            margin-left: 10px;
          }

          .point-number {
            font-size: 78px;
            font-weight: normal;
            line-height: 78px;
            letter-spacing: -3px;
          }
        }

        .content-icon-wrapper {
          width: 140px;
          height: 140px;
          background: $input-background-color;
          border-radius: 50%;
          margin-top: 48px;

          position: relative;

          .icon-point-1,
          .icon-point-2,
          .icon-point-3,
          .icon-point-4 {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          }

          .icon-point-1 {
            width: 60px;
            height: 96px;
          }

          .icon-point-2 {
            width: 76px;
            height: 72px;
          }

          .icon-point-3 {
            width: 68px;
            height: 68px;
          }

          .icon-point-4 {
            width: 76px;
            height: 85px;
          }
        }

        .content-caption {
          max-width: 313px;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          margin-top: 24px;
          margin-left: 15px;
          margin-right: 15px;
        }

        .content-description {
          max-width: 360px;
          font-size: 15px;
          text-align: center;
          margin: 13px 32px 32px 32px;
        }

        @media screen and (max-width: 992px){
          height: auto;
          min-height: 290px;
          margin-top: 60px;
          margin-left: auto;
          margin-right: auto;

          .point-number-wrapper {
            left: auto;
            top: -30px;

            .point-prefix {
              font-size: 15px;
              line-height: 15px;
              margin-left: 7px;
            }

            .point-number {
              font-size: 56px;
              line-height: 56px;
              letter-spacing: -3px;
            }
          }

          .content-icon-wrapper {
            width: 110px;
            height: 110px;

            .icon-point-1 {
              width: 40px;
              height: 64px;
            }

            .icon-point-2 {
              width: 62px;
              height: 58px;
            }

            .icon-point-3 {
              width: 62px;
              height: 62px;
            }

            .icon-point-4 {
              width: 62px;
              height: 69px;
            }
          }

          .content-caption {
            font-size: 22px;
            margin-top: 16px;
          }

          .content-description {
            font-size: 14px;
            margin-top: 10px;
          }
        }

        @media screen and (max-width: 576px){
          margin-left: 16px;
          margin-right: 16px;
        }
      }
    }
  }
}