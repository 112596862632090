@import "./colors";
@import "./font";

.customer-list-pagination-wrapper {
  .customer-list-pagination {
    border-top: 1px solid $disabled-color;
    margin-top: 56px;
    padding-top: 20px;

    .current-page {
      margin-top: 20px;
      margin-bottom: 16px;
      font-size: $font-size-pagination-current;
      font-weight: bold;
      color: black;
      text-align: center;
    }

    .customer-list-page-nav {
      display: flex;
      justify-content: center;

      .pagination {
        display: flex;
        justify-content: center;
        width: auto;
        gap: 8px;
        flex-wrap: wrap;

        .page-item {
          &.page-indices {
            .select-wrapper {
              width: 100px;
              .pagination-page-select {
                padding: 12px;
                width: 100%;
                font-size: 16px;
                font-weight: bold;
              }
              &::after {
                top: 12px;
              }
            }
          }

          .page-link {
            font-size: $font-size-pagination;
            border: none;
            border-radius: 12px;

            &.left,
            &.right,
            &.ellipsis {
              color: transparent;
            }

            &.left,
            &.right,
            &.left-last,
            &.right-last {
              height: 100%;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
            }

            &.left {
              //noinspection CssUnknownTarget
              background-image: url("/img/icons/arrow-left-primary.svg");
            }

            &.right {
              //noinspection CssUnknownTarget
              background-image: url("/img/icons/arrow-right-primary.svg");
            }

            &:not(.left):not(.right):not(.ellipsis) {
              background-color: $disabled-color;
              color: white;

              &:hover {
                opacity: 0.6;
              }
            }
          }

          &.active {
            .page-link {
              background-color: $primary-color !important;
              pointer-events: none;
              cursor: default;
            }
          }

          &.disabled {
            color: $disabled-color;

            .page-link.left {
              //noinspection CssUnknownTarget
              background-image: url("/img/icons/arrow-left-gray.svg");
            }

            .page-link.right {
              //noinspection CssUnknownTarget
              background-image: url("/img/icons/arrow-right-gray.svg");
            }

          }

          &.ellipsis {
            .page-link {
              height: 100%;
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              //noinspection CssUnknownTarget
              background-image: url("/img/icons/ellipsis.svg");
            }
          }
        }
      }
    }
  }
}