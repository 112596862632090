@import "../_colors";
@import "../font";

// 支払方法。
.page.checkout-payment {
  .payment-method {
    .form-check {
      display: flex;
      .payment-method-radio-label {
        font-size: $font-size-radio-label;
        display: flex;
        align-items: center;
        width: 352px;
        
        &::before,
        &::after, {
          top: auto;
        }
        
        .payment-method-name {
          display: inline-block;
          width: 150px;
        }
        
        .payment-fee-prefix {
          margin-right: auto;
        }

        @media screen and (max-width: 400px){
          font-size: $font-size-radio-label - 4px;
          width: 273px;

          .payment-method-name {
            display: inline-block;
            width: 112px;
          }
        }
      }
    }

    .payment-fee-notes {
      font-size: $font-size-radio-description;
      color: $text-color;
      padding-left: 24px;
    }
  }
}

.deferred-payment-description {
  font-size: 14px;
}