@import "../colors";

.photo-search-dialog.child-search-dialog,
.face-search-unavailable-dialog {
  .modal-body {
    overflow: auto;
  }

  .modal-footer {
    .close-button {
      color: $text-color-dark-alpha;

      .button-icon {
        img {
          opacity: 0.4;
        }
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

@mixin child-photo-checkbox-base {
  content: '';
  position: absolute;
  z-index: 30;
  display: block;
  width: 24px;
  height: 24px;
  top: 6px;
  left: 6px;
}

.child-photo-list {
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 4px;

  .child-photo-list-item {

    .child-photo-list-item-content {
      cursor: pointer;

      .photo-wrapper {
        position: relative;

        .child-photo {
          width: 192px;
          height: 192px;
          border-radius: 10px;
          border: 1px solid $photo-list-border-color;
          object-fit: contain;
        }

        &::before {
          @include child-photo-checkbox-base();
          //border: 2px solid $photo-list-border-color;
          border: 1px solid $text-color;
          background: white;
          border-radius: 10px;
        }

        &::after {
          @include child-photo-checkbox-base();
          display: none;

          //noinspection CssUnknownTarget
          background-image: url("/img/icons/check.svg");
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }

      .child-name {
        text-align: center;
        font-size: 16px;
      }

      &.checked {
        .photo-wrapper {
          .child-photo {
            border-width: 2px;
            border-color: $input-outline-color-focus;
          }

          &::before {
            border-color: $input-outline-color-focus;
            background-color: $input-outline-color-focus;
          }

          &::after {
            display: block;
          }
        }

        .child-name {
          font-weight: bold;
          color: $primary-color;
        }
      }
    }
  }
}

.face-search-unavailable-dialog {
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}