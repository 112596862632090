@import "font";

@mixin logout-button {
  width: 226px;
  border-radius: 27px;
  font-size: $font-size-pc-button-caption;
  padding-top: 12px;
  padding-bottom: 12px;
  .button-icon {
    width: 24px;
    height: 24px;
  }
}