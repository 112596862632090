@import "../_colors";
@import "../font";
@import "../design-constants";

.my-page-event-code {
  .alert {
    width: 100%;
  }
  
  .event-code-description {
    font-size: $font-size-event-description;
  }
}