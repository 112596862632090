$font-size-pc-icon-label: 12px;
$font-size-pc-nav-link: 14px;
$font-size-pc-input-label: 14px;
$font-size-pc-page-title-alphabet: 16px;
$font-size-pc-button-caption: 16px;
$font-size-pc-nav-brand: 24px;
$font-size-pc-page-title: 28px;

$font-size-sp-side-bar-link-alphabet: 11px;
$font-size-sp-page-title-alphabet: 12px;
$font-size-sp-side-bar-title: 16px;
$font-size-sp-side-bar-link: 16px;
$font-size-sp-nav-brand: 17px;
$font-size-sp-page-title: 24px;

$font-size-menu-alphabet: 11px;
$font-size-message: 15px;
$font-size-school-name: 13px;

$font-size-btn-md: 13px;

$font-size-event-description: 13px;
$font-size-alert-body: 14px;
$font-size-alert-title: 15px;
$font-size-my-page-section-header: 20px;
$font-size-section-title: 18px;
$font-size-section-title-supplement: 13px;

$font-size-photo-list-select-label: 12px;
$font-size-photo-list-name: 16px;
$font-size-photo-list-tax-include: 11px;
$font-size-photo-list-add-to-cart: 15px;
$font-size-photo-list-add-to-cart-sm: 11px;
$font-size-input: 14px;

$font-size-pagination-current: 12px;
$font-size-pagination: 14px;

$font-size-show-all: 14px;
$font-size-sp-number-of-cart-items: 11px;
$font-size-pc-number-of-cart-items-1: 14px;
$font-size-pc-number-of-cart-items-2: 13px;
$font-size-pc-number-of-cart-items-3: 12px;

$font-size-fixed-page-content: 14px;


$font-size-cart-list-event-title: 17px;
$font-size-cart-primary-category: 14px;
$font-size-cart-property-big: 16px;
$font-size-cart-property-md: 14px;
$font-size-cart-property-tax: 11px;
$font-size-cart-property-small-label: 12px;


$font-size-radio-label: 16px;
$font-size-radio-sm: 12px;
$font-size-radio-description: 13px;
$font-size-price-list-big: 15px;
$font-size-checkout-address: 15px;


$font-size-footer-link-pc: 14px;
$font-size-footer-link-sp: 13px;
$font-size-copy-right-pc: 12px;
$font-size-copy-right-sp: 11px;

$font-size-exhibition-room-price-title: 13px;

$font-size-photo-detail-price: 24px;