﻿@import "../_colors";
@import "../_font";

.regulation-table {
  th {
    display: block;
    border-top-width: 2px;
    border-bottom-width: 0;
    white-space: nowrap;
  }

  td {
    display: block;
    border-top-width: 1px;
    border-bottom-width: 0;
  }
  
  tr:last-child {
    th,
    td {
      border-bottom-width: 0;
    }

    td:last-child {
      border-bottom-width: 2px;
    }
  }

  .sub-list {
    list-style-type: circle;
  }

  .address-tel {
    span {
      display: none;
    }
  }
  
  $td-padding-left: 8px;
  .payment-method {
    padding-left: $td-padding-left + $font-size-fixed-page-content;
  }

  .payment-description {
    padding-left: $td-padding-left + $font-size-fixed-page-content;
    border-top-width: 0;

    .rakuten-pay-logo {
      width: 100px;
      margin-right: 12px;
    }

    .score-atobarai-logo {
      width: 200px;
      margin-right: 12px;
    }
  }
  
  @media screen and (min-width: 768px) {
    th,
    td {
      display: table-cell;
      border-top-width: 1px;
      border-bottom-width: 0;
    }

    td:last-child {
      margin-left: 0;
    }
    
    tr:last-child {
      border-bottom-width: 1px;
      
      th,
      td {
        border-bottom-width: 0;
      }

      td:last-child {
        border-bottom-width: 0;
      }
    }
    
    .address-tel {
      div,
      span {
        display: inline; 
      }
    }

    .payment-method {
      padding-left: 0;
    }

    .payment-description {
      padding-left: 0;
      border-top-width: 1px;
    }
  }
}