.page.checkout {

  .group-photo-recommendation {
    > .col-12 {
      display: flex;
      justify-content: center;
      align-items: center;

      .alert {
        
        .alert-title {
          font-size: 14px;
        }

        .alert-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .group-photo-message {
            margin-bottom: 0.5em;
            font-size: 13px;
          }

          .group-photo-tertiary-category-list {
            display: inline-block;
            text-align: left;
            list-style: initial;
            padding-left: 12px;
            padding-right: 12px;
            li {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .return-button {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 24px;
      height: 24px;
      left: 16px;

      //noinspection CssUnknownTarget
      background-image: url("/img/icons/arrow-return-left-gray.svg");
    }
  }

  .place-order-button {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 24px;
      height: 24px;
      right: 16px;

      //noinspection CssUnknownTarget
      background-image: url("/img/icons/check.svg");
    }
  }
}

@import "confirmation-regulation";
