﻿@import "../_design-constants";
@import "../_colors";

.page.my-page-child-information {
  .child-information {
    .face-recognition-photo-container {
      .face-recognition-photo-wrapper {
        visibility: hidden;
        position: relative;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        background: $input-background-color;
        border: 1px solid #ced4da;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;

        .face-recognition-photo {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .action-row {
          position: absolute;
          bottom: 8px;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 32px;
          
          .edit-button,
          .delete-button {
            width: 44px;
            height: 44px;
            background: #FFFCF2;
            padding: 8px;
            border-radius: 22px;
            border: 1px solid #ced4da;
          }
        }
      }

      .face-recognition-placeholder-wrapper {
        display: none;
        visibility: hidden;
        margin-left: auto;
        margin-right: auto;
        background: $input-background-color;
        border: 1px solid #ced4da;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;

        .upload-icon-wrapper {
          width: 100%;
          height: 100%;
          min-height: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          i, span {
            display: block;
          }

          span {
            color: $primary-color;
            font-weight: normal;
          }
        }
      }
      
      input[type="file"] {
        display: none;
      }
    }
  }
}