﻿@import "./_colors";
@import "./_design-constants";

.to-top-container-for-index {
  position: relative;
  max-width: $navbar-max-width;
  margin-left: calc((100% - #{$navbar-max-width}) / 2);

  @media screen and (max-width: $header-collapse-max-width) {
    position: static;
    margin: 36px 0;
  }

  .to-top-wrapper-for-index {
    position: absolute;
    right: 0;
    bottom: -200px;
    z-index: 1;

    @media screen and (max-width: $header-collapse-max-width) {
      position: static;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .to-top-icon-wrapper-for-index {
      width: 60px;
      height: 60px;
      padding-top: 18px;
      padding-bottom: 18px;
      margin-bottom: 9px;
      border-radius: 30px;
      background: $secondary-color;
      box-shadow: 0 3px 20px #0000001A;

      a.btn {
        padding: 0;
      }

      .to-top-icon {
        width: 24px;
        height: 24px;
      }
    }

    .to-top-icon-caption-for-index {
      font-weight: normal;
      color: $secondary-color;
    }
  }
}