@import "../colors";
@import "../font";

.page.thanks {
  .message-row {
    font-size: $font-size-fixed-page-content;
    font-weight: normal;

    .bank-account {
      border: 1px solid $border-color-black-alpha-2;
      padding: 12px;
      margin-bottom: 12px;

      .bank-account-title {
        font-weight: bold;
      }

      .bank-account-value {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        
        > span {
          white-space: nowrap;
        }
      }
    }
  }
  
  .thanks-message {
    text-align: center;
  }

  .image-row {
    .image-wrapper {
      position: relative;

      .image-padding-dummy {
        padding-top: 66.2566712041659%;
      }

      .thanks-image-wrapper {
        position: absolute;
        top: 6.6%;
        left: 9%;
        width: 84%;
        height: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 40px 0 40px 0;
        }
      }

      .particle-image {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
      }
    }
  }
}