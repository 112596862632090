@import "../colors";
@import "../font";

.page.checkout,
.page.my-page.order-detail {
  .checkout-address {

    .checkout-address-detail {
      font-size: $font-size-checkout-address;
      color: black;
      position: relative;

      .detail-row {
        padding-left: 28px;
        border-bottom: 1px solid $border-color-black-alpha-1;
        padding-bottom: 16px;
        margin-top: 16px;
        margin-bottom: 16px;

        &::before {
          content: '';
          position: absolute;
          width: 24px;
          height: 24px;
          left: 0;
          right: 0;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &.shipping-method {
          .shipping-fee {
            margin-left: 8px;
          }

          &::before {
            //noinspection CssUnknownTarget
            background-image: url("/img/icons/delivery.svg");
          }
        }

        &.payment-method {
          display: flex;
          align-items: center;

          .payment-method-row {
            display: flex;
            align-items: center;
          }

          .payment-method-name {
            width: 120px;
          }

          .payment-fee {
            margin-left: 8px;
          }

          &::before {
            //noinspection CssUnknownTarget
            background-image: url("/img/icons/payments-finance-38.svg");
          }
        }

        &.address-and-name::before {
          //noinspection CssUnknownTarget
          background-image: url("/img/icons/map.svg");
        }

        &.phone-number::before {
          //noinspection CssUnknownTarget
          background-image: url("/img/icons/phone.svg");
        }

        &.bank-transfer-account {
          .bank-transfer-account-line {
            & > span {
              word-break: keep-all;
            }
          }
        }
      }
    }
  }
}