.page.checkout-customer-information,
.page.my-page-child-information {
  .customer-information,
  .child-information {
    margin-bottom: 24px;
    .fields-row {
      $column-gap: 12px;
      margin-bottom: 48px;
      
      display: flex;
      flex-wrap: wrap;
      column-gap: $column-gap;
      row-gap: 0;
      
      $margin-y: 60px;
      $school-year-width: 96px;
      $school-class-width: 190px;
      $school-number-width: 120px;
      $school-information-row-width: $school-year-width + $school-class-width + $school-number-width + 2 * $column-gap + $margin-y;
      
      @media screen and (max-width: $school-information-row-width - 1){
        flex-direction: column;
        row-gap: 16px;
      }

      .school-year,
      .school-class {
        margin-bottom: 4px;
      }

      .school-year {
        width: $school-year-width;
      }

      .school-class {
        width: $school-class-width;
      }

      .student-number {
        width: $school-number-width;
      }
    }
  }
}