@import "../colors";

.scale-reset-button {
  font-size: 12px;
  color: $secondary-color;
  
  &, &:hover, &:active {
    color: $secondary-color;
  }
 
  &__icon-image {
    width: 12px;
    height: 12px;
  }
}