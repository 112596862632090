.labeled-drop-down {
  display: flex;
  gap: 12px;

  &__label {
    padding: 6px;
  }

  &__select-wrapper {
    position: relative;
  }

  &__select {
    width: 100%;
    padding: 6px 12px 6px 8px;
  }

  & &__select-wrapper {
    &::after {
      width: 20px;
      height: 20px;
      right: 1px;
      top: 3px;
    }
  }
}