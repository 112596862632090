@import "../_colors";
@import "../_design-constants";
@import "./_top-page-constants";

.section {
  max-width: $top-page-design-width;
  margin-left: auto;
  margin-right: auto;
}

.top-page {
  position: relative;
  background: linear-gradient(to bottom, transparent 385px, $background-color 385px, $background-color 1075px, transparent 1075px);
}
