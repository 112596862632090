@import "./_colors";
@import "font";
@import "./design-constants";

.global-alert {
  max-width: $design-width;
  margin: 0 auto 0 auto;
  
  .col-12 {
    .alert:first-child {
      margin-top: 18px;
    }
    .alert:last-child {
      margin-bottom: 36px;
    }
  }
}

.alert.alert-danger {

  // Bootstrap独自
  //background-color: #f8d7da;
  //border-color: #f5c6cb;

  background-color: $tertiary-background-color;
  border-color: $tertiary-background-color;
  color: $tertiary-color;
}

.alert.alert-primary {
  background-color: $primary-background-color-alpha;
  border-color: $primary-background-color-alpha;
  color: $primary-color;
}

.alert.alert-success {
  background-color: $alert-success-background-color-alpha;
  border-color: $alert-success-background-color-alpha;
  color: black;
}

.alert-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .alert-message-wrapper {
    font-weight: bold;
    text-align: center;
    flex-grow: 1;
    word-break: break-word;

    i {
      margin-right: 8px;
      vertical-align: 2px;
      
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.alert {
  padding: 20px;
  border-radius: 10px;

  .alert-title {
    display: flex;
    justify-content: center;
    font-size: $font-size-alert-title;
    font-weight: bold;
    color: black;
    margin-bottom: 16px;
  }

  .alert-body {
    text-align: center;
    font-size: $font-size-alert-body;
    color: black;
  }

  &.alert-primary {
    .alert-body {
      .alert-strong {
        color: $primary-background-color;
      }
    }
  }
  
  &.alert-danger {
    .alert-body {
      .alert-strong {
        color: $tertiary-color;
      }
    }
  }

  &.alert-success {
    .alert-body {
      .alert-strong {
        color: $alert-success-background-color;
      }
    }
  }

  .alert-strong {
    font-weight: bold;
  }
}