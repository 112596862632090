@use "sass:math";
@import "../_colors";
@import "../font";
//@import "../design-constants";

.cart-link {
  position: relative;

  .number-of-cart-items {
    display: none;

    &.has-any-items {
      display: block;
      color: white;
      background: $tertiary-color;
    }
  }
}

header .navbar .navbar-nav .nav-item {
  .icon-link.cart-link {
    position: relative;

    span.number-of-cart-items {
      position: absolute;
      top: -4px;
      border-radius: 10px;
      color: white;
      font-size: $font-size-pc-number-of-cart-items-3;
      right: 6px;
      padding-left: 2px;
      padding-right: 2px;
      
      &.item-digit-1 {
        font-size: $font-size-pc-number-of-cart-items-1;
        right: 10px;
        padding-left: 6px;
        padding-right: 6px;
      }
      &.item-digit-2 {
        font-size: $font-size-pc-number-of-cart-items-2;
        right: 9px;
        padding-left: 3px;
        padding-right: 3px;
      }
    }
  }
}

.nav-link-sm.cart-link {
  .number-of-cart-items {
    position: absolute;
    top: 0;
    border-radius: 10px;
    font-size: $font-size-sp-number-of-cart-items;

    left: 11px;
    padding-left: 2px;
    padding-right: 2px;

    &.item-digit-1 {
      left: 9px;
      padding-left: 4px;
      padding-right: 4px;
    }

    &.item-digit-2 {
      left: 8px;
      padding-left: 2px;
      padding-right: 2px;
    }
    
    &.item-digit-3 {
      left: 7px;
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}

// ミニカーとのコンテナ (カートボタンとミニカートの外側)
.mini-cart-container {
  position: relative;
  display: inline;

  @media(max-width: 991px) {
    display: none;
  }
}

.mini-cart {
  width: 420px;
  position: absolute;
  right: 0;
  top: -8px;
  z-index: 3;

  &:not(.show) {
    display: none;
  }

  border-radius: 30px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);

  .mini-cart-header {
    background: $primary-color;
    border-radius: 30px 30px 0 0;
    color: white;
    padding: 12px;
    text-align: center;
    position: relative;

    .close-mini-cart-button {
      position: absolute;
      right: 18px;
      top: 6px;
    }
  }

  .mini-cart-body {
    padding: 12px;
    background: white;
    border-radius: 0 0 30px 30px;

    $row-margin: 12px;

    .mini-cart-total-price-row {
      text-align: right;
      margin-bottom: $row-margin;

      .price-label, .price-value, .price-unit {
        font-weight: bold;
      }

      .price-tax {
        font-size: $font-size-pc-input-label;
      }
    }

    .mini-cart-action-row {
      margin-bottom: $row-margin;

      .button-row {
        margin-bottom: math.div($row-margin, 3);
        text-align: center;
      }

      .link-to-cart {
        .btn {
          width: 280px;
        }
      }
    }

    .mini-cart-list-row {
      margin-bottom: $row-margin;

      .mini-cart-list-item {
        display: flex;
        gap: $row-margin;

        padding-top: $row-margin;
        padding-bottom: $row-margin;

        &:not(:first-child) {
          border-top: 1px solid $border-color-black;
        }

        .photo-wrapper {

          img.cart-photo {
            max-width: 150px;
            max-height: 150px;
            object-fit: contain;
          }
        }

        .photo-property {
          width: 100%;

          .photo-property-item {
            .label {
              &::after {
                content: '：';
              }
            }

            &.price {
              width: 100%;
              display: flex;

              .price {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}