* {
  font-family: 'gjg', 'Noto Sans JP', sans-serif;
  font-weight: 300;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  &.list-style-none {
    list-style: none;
  }
}

body {
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  min-width: 320px;
  width: 100%;

  padding-bottom: 572px;

  @media screen and (max-width: 991px) {
    padding-bottom: 702px;
  }

  @media screen and (max-width: 691px) {
    padding-bottom: 810px;
  }
}

.btn-link {
  text-decoration: none;
}

a,
a:hover,
a:visited,
a:focus,
a:active {
  text-decoration: none;
}


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import "./cart/cart";
@import "./cart/cart-detail";
@import "./cart/cart-photo-cell";
@import "./cart/mini-cart";
@import "./checkout/address-form";
@import "./checkout/confirmation";
@import "./checkout/payment";
@import "./checkout/price-list";
@import "./checkout/shipping";
@import "./checkout/thanks";
@import "./common/index";
@import "./exhibition-room/exhibition-room";
@import "./fixed-page/common";
@import "./fixed-page/faq";
@import "./fixed-page/how-to-use";
@import "./fixed-page/how-to-use-point";
@import "./fixed-page/regulation";
@import "./front-item-list/front-item-list";
@import "./top/top-page";
@import "./top/carousel";
@import "./top/login-form";
@import "./top/about";
@import "./photos/check-actions";
@import "./photos/photo-child-search-dialog";
@import "./photos/photo-detail";
@import "./photos/photo-list";
@import "./photos/photo-search-dialog";
@import "./photos/photo-search-result";
@import "./photos/photo-search-status-container";
@import "./my-page/account";
@import "./my-page/event-code-input";
@import "./my-page/order-history";
@import "./my-page/face-recognition-photo";
@import "./my-page/face-recognition-notes-dialog";
@import "./action-row";
@import "./alert";
@import "./block-screen-shot";
@import "./breadcrumb";
@import "./button-icon";
@import "./cart-item-list";
@import "./child-information";
@import "./collapse";
@import "./color-class";
@import "./common";
@import "./customer-information";
@import "./customer-pagination";
@import "./design-constants";
@import "./font-faces";
@import "./footer";
@import "./header";
@import "./my-page";
@import "./my-page-menu";
@import "./my-page-nav";
@import "./password-input-field";
@import "./side-menu";
@import "./to-up-and-bottom-button";
@import "./to-top-button-for-index";
@import "./tooltip";
