@import "../colors";
@import "../font";

.photo-search-dialog {
  width: 100vw;
  margin-left: auto;
  margin-right: auto;

  .modal-dialog {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 640px;

    .modal-content {
      height: 100%;

      .modal-header {
        border: none;
        margin-bottom: 20px;
        padding-bottom: 0;
      }

      .modal-body {
        padding-top: 0;
      }

      .modal-footer {
        min-height: 142px;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        
        .close-button {
          color: $text-color-dark-alpha;
          .button-icon {
            img {
              opacity: 0.4;
            }
          }
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

.event-search-dialog {
  .modal-dialog {
    .modal-content {
      .modal-header {
      }

      .modal-body {
        padding-top: 0;
        height: calc(100% - 140px - 94px);
        overflow: auto;

        .event-list {
          .event-list-item {
            margin-left: 8px;
            margin-bottom: 36px ;
            .tertiary-category-list {
              margin-top: 12px;
              display: flex;
              flex-wrap: wrap;
              gap: 12px;
              padding-left: 32px;
              .tertiary-category-list-item {
                
              }
            }
          }
        }
      }

      .modal-footer {
      }
    }
  }
}