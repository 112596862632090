@import "../colors";

.photo-price-label {
  width: 150px;
  &__price {
    font-size: 28px;
    font-weight: bold;
    color:$primary-color;
  }
  &__unit {
    font-size: 24px;
    font-weight: bold;
    color:$primary-color;
  }
}